import { ArrowDownward, ArrowUpward, Delete, ModeEdit } from '@mui/icons-material';
import {
    Button,
    Paper,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

export const EscoposList = ({
    escopos,
    handleEditar,
    handleExcluir,
    handleOrdenar,
    salvarEscopoDefault,
    ordenacao,
}) => {
    const [selectedId, setSelectedId] = useState();

    const handleChangeRadio = async (id) => {
        setSelectedId(id);
        await salvarEscopoDefault(id);
    };

    const editarEscopo = (escopo) => {
        handleEditar(escopo);
    };

    const excluirEscopo = (escopo) => {
        handleExcluir(escopo);
    };

    const isOrdenadoAsc = (campo) => {
        return ordenacao === `${campo}_asc`;
    };

    const isOrdenadoDesc = (campo) => {
        return ordenacao === `${campo}_desc`;
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#424242',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const iconStyle = {
        fontSize: '14px',
    };

    useEffect(() => {
        const initialCheckedId = escopos.find((escopo) => escopo.padrao === 1)?.id;
        if (initialCheckedId !== undefined) {
            setSelectedId(initialCheckedId);
        }
    }, [escopos]);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell
                            onClick={() => handleOrdenar('categoria')}
                            style={{
                                cursor: 'pointer',
                                fontWeight:
                                    isOrdenadoAsc('categoria') || isOrdenadoDesc('categoria') ? 'bold' : 'normal',
                            }}
                        >
                            Categoria {isOrdenadoAsc('categoria') && <ArrowUpward style={iconStyle} />}{' '}
                            {isOrdenadoDesc('categoria') && <ArrowDownward style={iconStyle} />}
                        </StyledTableCell>
                        <StyledTableCell
                            onClick={() => handleOrdenar('subcategoria')}
                            style={{
                                cursor: 'pointer',
                                fontWeight:
                                    isOrdenadoAsc('subcategoria') || isOrdenadoDesc('subcategoria') ? 'bold' : 'normal',
                            }}
                        >
                            Subcategoria {isOrdenadoAsc('subcategoria') && <ArrowUpward style={iconStyle} />}{' '}
                            {isOrdenadoDesc('subcategoria') && <ArrowDownward style={iconStyle} />}
                        </StyledTableCell>
                        <StyledTableCell>Default</StyledTableCell>
                        <StyledTableCell
                            onClick={() => handleOrdenar('direitos')}
                            style={{
                                cursor: 'pointer',
                                fontWeight: isOrdenadoAsc('direitos') || isOrdenadoDesc('direitos') ? 'bold' : 'normal',
                            }}
                        >
                            Direitos {isOrdenadoAsc('direitos') && <ArrowUpward style={iconStyle} />}{' '}
                            {isOrdenadoDesc('direitos') && <ArrowDownward style={iconStyle} />}
                        </StyledTableCell>
                        <StyledTableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {escopos.length > 0 &&
                        escopos.map((escopo) => (
                            <TableRow
                                key={escopo.id}
                                className="nowrap"
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {escopo.categoria}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {escopo.subcategoria}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Radio
                                        checked={selectedId === escopo.id}
                                        onClick={() => handleChangeRadio(escopo.id)}
                                    />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {escopo.direitos ? 'Sim' : 'Não'}
                                </TableCell>
                                <TableCell align="right">
                                    <Button
                                        aria-label="Editar Escopo"
                                        size="small"
                                        onClick={() => editarEscopo(escopo)}
                                        sx={{ color: 'var(--primary-color)' }}
                                    >
                                        <ModeEdit />
                                    </Button>

                                    <Button
                                        aria-label="delete"
                                        size="small"
                                        onClick={() => excluirEscopo(escopo)}
                                        sx={{ color: 'var(--primary-color)' }}
                                    >
                                        <Delete fontSize="small" />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
