import React, { useRef } from 'react';
import { Box } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const TextEditor = ({ setEditorContent, editorContent, isReadOnly = false, height = '50vh' }) => {
    const quillRef = useRef(null);

    const handleChange = (content) => {
        if (!isReadOnly) {
            setEditorContent(content);
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <ReactQuill
                ref={quillRef}
                theme="snow"
                value={editorContent}
                onChange={handleChange}
                readOnly={isReadOnly}
                style={{ height, marginBottom: '50px', color: 'var(--grey-900-color)', fontSize: '18px' }}
            />
        </Box>
    );
};
