import React, { useState } from 'react';
import { FormControl, OutlinedInput, Box } from '@mui/material';
import { GenericModal } from '../../common/GenericModal';
import { ButtonFL } from '../../ButtonFL';
import { TextEditor } from '../../../components/TextEditor';

export const CampainEditBriefingModal = ({ item, onSave, open, onClose }) => {
    const decodedBriefing = decodeURIComponent(item?.escopoBriefing || item?.campanhaBriefing || '');

    const [editorContent, setEditorContent] = useState(decodedBriefing);

    const handleSave = () => {
        const updatedItem = {
            ...item,
            escopoBriefing: editorContent,
        };

        onSave(updatedItem);
    };

    return (
        <GenericModal open={open} onClose={onClose} bgColor={'var(--white-color)'}>
            <Box sx={{ pt: 6, pb: 2, px: 3 }}>
                <TextEditor setEditorContent={setEditorContent} editorContent={editorContent} />

                <ButtonFL textKey="Salvar" onClick={handleSave} fullWidth />
            </Box>
        </GenericModal>
    );
};
