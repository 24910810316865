import { useEffect, useState } from 'react';

import { AddCircle } from '@mui/icons-material';
import { ButtonFL } from '../../components/ButtonFL';

import styles from '../../_assets/css/generic.module.css';
import { mensagemVazio, showMessage } from '../../data/Interfaces';
import { Excluir, Get, Patch, Post } from '../../data/Verbs';
import { Loader } from '../../components/Loader';
import { Alert } from '../../components/common/Alert';
import { Projeto } from './Projeto';
import { ProjetosSearch } from './ProjetoSearch';
import { ProjetosList } from './ProjetosList';
import { ProjetosMessage } from './ProjetosMessage';
import { ProjetosToExcel } from './ProjetosToExcel';
import { useError } from '../../contexts/ErrorContext';

export function Projetos() {
    const [query, setQuery] = useState('');
    const [ordenacao, setOrdenacao] = useState('id_desc');
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState(mensagemVazio);
    const [projetos, setProjetos] = useState([]);
    const [abreProjeto, setAbreProjeto] = useState({
        status: false,
        projeto: {
            id: '0',
        },
    });

    const { showError } = useError();

    const salvarProjeto = async (projeto) => {
        try {
            setIsLoading(true);

            const url = `${process.env.REACT_APP_API_URL}/projetos`;
            let message = 'Projeto inserido com sucesso';

            if (projeto.id === '0') {
                delete projeto.id;
                await Post(url, projeto);
            } else {
                await Patch(`${url}/${projeto.id}`, projeto);
                message = 'Projeto alterado com sucesso';
            }

            closeProjeto();
            showMessage(
                {
                    variant: 'success',
                    message,
                },
                setMessage,
            );
        } catch (error) {
            closeProjeto();
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const ExcluirProjeto = async (projeto) => {
        try {
            setIsLoading(true);
            await Excluir(`${process.env.REACT_APP_API_URL}/projetos/${projeto.id}`);

            closeProjeto();
            showMessage(
                {
                    variant: 'warning',
                    message: 'Projeto excluido com sucesso',
                },
                setMessage,
            );
        } catch (error) {
            closeProjeto();
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleBusca = (_query = 'all') => {
        setIsLoading(true);
        setQuery(_query);
        setIsLoading(false);
    };

    const openProjeto = (_projeto) => {
        setIsLoading(true);

        setAbreProjeto({
            status: true,
            projeto: _projeto,
        });
        setIsLoading(false);
    };

    const closeProjeto = () => {
        setIsLoading(true);

        setAbreProjeto({
            status: false,
            projeto: {
                id: '0',
            },
        });
        setIsLoading(false);

        const url = `${process.env.REACT_APP_API_URL}/projetos`;
        let message = 'Projeto inserido com sucesso';
    };

    const handleOrdenar = (campo) => {
        setIsLoading(true);

        if (ordenacao === `${campo}_asc`) {
            setOrdenacao(`${campo}_desc`);
        } else {
            setOrdenacao(`${campo}_asc`);
        }
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const data = await Get(`${process.env.REACT_APP_API_URL}/projetos?query=${query}&sort=${ordenacao}`);

                setProjetos(data);
            } catch (error) {
                showError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUsers();
    }, [query, ordenacao, abreProjeto]);

    return (
        <>
            <div className={styles.container}>
                {message.message && <Alert variant={message.variant} message={message.message} />}
            </div>

            {isLoading && <Loader />}

            {abreProjeto.status ? (
                <Projeto projeto={abreProjeto.projeto} handleSalvar={salvarProjeto} handleFechar={closeProjeto} />
            ) : (
                <div className={styles.container}>
                    <ProjetosSearch
                        query={query}
                        handleAdicionar={
                            <ButtonFL
                                onClick={() =>
                                    openProjeto({
                                        id: '0',
                                    })
                                }
                                projeto={abreProjeto.projeto}
                                startIcon={<AddCircle />}
                                sx={{ mt: 1, mb: 1 }}
                                textKey="Adicionar"
                            />
                        }
                        handleBusca={handleBusca}
                    />

                    <ProjetosMessage
                        message={
                            query == ''
                                ? 'Mostrando todos os Projetos '
                                : projetos.length == 0
                                ? 'Não foram encontrados projetos cadastrados'
                                : `Listando ${projetos.length} projetos para a sua busca: ${query}`
                        }
                    />

                    <ProjetosList
                        handleEditar={openProjeto}
                        handleExcluir={ExcluirProjeto}
                        handleOrdenar={handleOrdenar}
                        projetos={projetos}
                        ordenacao={ordenacao}
                    />

                    <ProjetosToExcel />
                </div>
            )}
        </>
    );
}
