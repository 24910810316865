import React, { useState, useEffect } from 'react';
import { sortObject } from '../../data/Utils';
import { CampainCard } from '../../components/CampainCard';
import { CampainCardDetailsModal } from '../../components/CampainItemModal/CampainCardDetailsModal';

export function CampanhaItensList({ campanhaItens, updateCampanhaItem, setUpdated, updated, tab }) {
    const sortedItems = sortObject([...campanhaItens], ['subcategoria']);

    const [editModes, setEditModes] = useState(sortedItems.map(() => false));
    const [openModal, setOpenModal] = useState({ isOpen: false, type: null });

    const [selectedItem, setSelectedItem] = useState(null);
    const [itemIdCampain, setItemIdCampain] = useState(null);

    const handleOpenModal = (item, modalType) => {
        setSelectedItem(item);
        setOpenModal({ isOpen: true, type: modalType });
    };

    const handleCloseModal = () => {
        setSelectedItem(null);
        setOpenModal({ isOpen: false, type: null });
    };

    const handleOnEditCampanhaItem = (index) => {
        const updatedEditModes = [...editModes];
        updatedEditModes[index] = !editModes[index];
        setEditModes(updatedEditModes);
    };

    const handleOnOpenCampanhaItem = (item) => {
        handleOpenModal(item, 'itemDetail');
    };

    useEffect(() => {
        const itemUpdated = campanhaItens?.find((item) => item.id == itemIdCampain);
        setSelectedItem(itemUpdated);
    }, [campanhaItens]);

    return (
        <>
            {openModal.isOpen && openModal.type == 'itemDetail' && (
                <CampainCardDetailsModal
                    item={selectedItem}
                    open={openModal}
                    onClose={handleCloseModal}
                    updateCampanhaItem={updateCampanhaItem}
                    updated={updated}
                    setUpdated={setUpdated}
                    handleOnEditCampanhaItem={handleOnEditCampanhaItem}
                    setItemIdCampain={setItemIdCampain}
                    itemIdCampain={itemIdCampain}
                    campanhaItens={campanhaItens}
                />
            )}

            {sortedItems.map((item) => (
                <CampainCard key={item.id} campain={item} tab={tab} handleOpenModal={handleOnOpenCampanhaItem} />
            ))}
        </>
    );
}
