import { Category } from '@mui/icons-material';
import { Avatar, Box, Button, Checkbox, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { ButtonFL } from '../../components/ButtonFL';

export const Escopo = ({ escopo, handleFechar, handleSalvar }) => {
    const [form, setForm] = useState({
        categoria: escopo.categoria || '',
        subcategoria: escopo.subcategoria || '',
        direitos: escopo.direitos || false,
    });

    const handleSubmit = (event) => {
        event.preventDefault();

        const updated = {
            ...escopo,
            categoria: form.categoria,
            subcategoria: form.subcategoria,
            direitos: form.direitos,
        };

        handleSalvar(updated);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name === 'direitos') {
            setForm({
                ...form,
                [name]: !form.direitos,
            });
        } else {
            setForm({
                ...form,
                [name]: value,
            });
        }
    };

    return (
        <Box
            sx={{
                margin: 2,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'var(--white-color)',
            }}
        >
            <Avatar
                sx={{
                    m: 1,
                    mt: 3,
                    bgcolor: 'var(--primary-color)',
                }}
            >
                <Category />
            </Avatar>

            <Typography component="h1" variant="h5">
                {escopo.id ? 'Adicionar Tipo de Entrega' : 'Alterar Tipo de Entrega'}
            </Typography>

            <Box component="form" onSubmit={handleSubmit} sx={{ boxSizing: 'border-box', width: '100%', p: 4 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="categoria"
                    id="categoria"
                    label="Categoria"
                    value={form.categoria}
                    onChange={handleInputChange}
                />

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="subcategoria"
                    id="subcategoria"
                    label="Subcategoria"
                    value={form.subcategoria}
                    onChange={handleInputChange}
                />

                <Checkbox name="direitos" id="direitos" checked={form.direitos} onChange={handleInputChange} />
                <label htmlFor="direitos">
                    Direitos <small>(caso esta opção seja habilitada, não entra no fluxo de workflow)</small>
                </label>

                <ButtonFL type="submit" fullWidth textKey="Salvar" />
                <ButtonFL fullWidth textKey="Voltar" onClick={() => handleFechar(false)} />
            </Box>
        </Box>
    );
};
