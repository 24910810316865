import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';

const firebaseConfig = {
    apiKey: 'AIzaSyCFL5LDpUkkwyw-Y0SS-gk3jt0TolhI-CE',
    authDomain: 'omnicontent-6fe42.firebaseapp.com',
    projectId: 'omnicontent-6fe42',
    storageBucket: 'omnicontent-6fe42.appspot.com',
    messagingSenderId: '197465338466',
    appId: '1:197465338466:web:b2ff4e09c5ae370875d73b',
    measurementId: 'G-0DJKVD8MMV',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const performance = getPerformance(app);

export { analytics, performance };
