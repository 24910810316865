import React from 'react';
import { Typography, Box } from '@mui/material';
import { ChatsMensagem } from './ChatsMensagem';
import { useAuthStore } from '../../store';

const ChatsMensagens = ({ chats }) => {
    const user = useAuthStore((state) => state.user);

    return (
        <>
            {chats?.length > 0 ? (
                chats?.map((chat, index) => {
                    const { tipoChat } = chat;
                    const isMyMessage = tipoChat == 1 && user.id == chat.idusuario;
                    return (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: isMyMessage ? 'flex-end' : 'flex-start',
                            }}
                        >
                            <ChatsMensagem chat={chat} isMyMessage={isMyMessage} />
                        </Box>
                    );
                })
            ) : (
                <Typography variant="caption">Não há mensagem</Typography>
            )}
        </>
    );
};

export default ChatsMensagens;
