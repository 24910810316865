import { useEffect, useState } from 'react';

import { AddCircle } from '@mui/icons-material';
import { ButtonFL } from '../../components/ButtonFL';

import styles from '../../_assets/css/generic.module.css';
import { mensagemVazio, showMessage } from '../../data/Interfaces';
import { Excluir, Get, Patch, Post } from '../../data/Verbs';
import { Loader } from '../../components/Loader';
import { Alert } from '../../components/common/Alert';
import { Escopo } from './Escopo';
import { EscoposSearch } from './EscoposSearch';
import { EscoposList } from './EscoposList';
import { EscoposMessage } from './EscoposMessage';
import { apiUpdateDefaultEscopo } from '../../services/apiUpdateDefaultEscopo';

export function Escopos() {
    const [query, setQuery] = useState(localStorage.getItem('escopos-query') || '');
    const [ordenacao, setOrdenacao] = useState('categoria_asc');
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState(mensagemVazio);
    const [escopos, setEscopos] = useState([]);
    const [abreEscopo, setAbreEscopo] = useState({
        status: false,
        escopo: {
            id: '0',
        },
    });

    const salvarEscopo = async (escopo) => {
        setIsLoading(true);

        const url = `${process.env.REACT_APP_API_URL}/escopos`;
        let message = 'Escopo inserido com sucesso';

        try {
            if (escopo.id === '0') {
                delete escopo.id;

                await Post(url, escopo);
            } else {
                await Patch(`${url}/${escopo.id}`, escopo);
                message = 'Escopo alterado com sucesso';
            }

            closeEscopo();
            showMessage(
                {
                    variant: 'success',
                    message,
                },
                setMessage,
            );
        } catch (error) {
            closeEscopo();
            showMessage(
                {
                    variant: 'warning',
                    message: error.message,
                },
                setMessage,
            );
        }
    };

    const salvarEscopoDefault = async (id) => {
        apiUpdateDefaultEscopo(id);
    };

    const ExcluirEscopo = async (escopo) => {
        setIsLoading(true);

        try {
            await Excluir(`${process.env.REACT_APP_API_URL}/escopos/${escopo.id}`);

            closeEscopo();
            showMessage(
                {
                    variant: 'warning',
                    message: 'Escopo excluido com sucesso',
                },
                setMessage,
            );
        } catch (error) {
            closeEscopo();

            showMessage(
                {
                    variant: 'error',
                    message: error.message,
                },
                setMessage,
            );
        }
    };

    const handleBusca = (_query) => {
        setIsLoading(true);

        setQuery(_query);
    };

    const openEscopo = (_escopo) => {
        setIsLoading(true);

        setAbreEscopo({
            status: true,
            escopo: _escopo,
        });
    };

    const closeEscopo = () => {
        setIsLoading(true);

        setAbreEscopo({
            status: false,
            escopo: {
                id: '0',
            },
        });
    };

    const handleOrdenar = (campo) => {
        setIsLoading(true);

        if (ordenacao === `${campo}_asc`) {
            setOrdenacao(`${campo}_desc`);
        } else {
            setOrdenacao(`${campo}_asc`);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const search = query ? `query=${query}&` : '';
            const data = await Get(`${process.env.REACT_APP_API_URL}/escopos?${search}sort=${ordenacao}`);

            setEscopos(data);
            setIsLoading(false);
        };

        fetchData();
    }, [query, ordenacao, abreEscopo]);

    useEffect(() => {
        localStorage.setItem('escopos-query', query);
    }, [query]);

    return (
        <>
            <div className={styles.container}>
                {message.message && <Alert variant={message.variant} message={message.message} />}
            </div>

            {isLoading && <Loader />}

            {abreEscopo.status ? (
                <Escopo escopo={abreEscopo.escopo} handleSalvar={salvarEscopo} handleFechar={closeEscopo} />
            ) : (
                <div className={styles.container}>
                    <EscoposSearch
                        query={query}
                        handleAdicionar={
                            <ButtonFL
                                onClick={() =>
                                    openEscopo({
                                        id: '0',
                                    })
                                }
                                cliente={abreEscopo.cliente}
                                startIcon={<AddCircle />}
                                sx={{ mt: 1, mb: 1 }}
                                textKey="Adicionar"
                            />
                        }
                        handleBusca={handleBusca}
                    />

                    <EscoposMessage
                        message={
                            escopos.length === 0
                                ? 'Não temos nenhum escopos cadastrado'
                                : `Listando ${escopos.length} escopos para a sua busca: ${query}`
                        }
                    />

                    <EscoposList
                        handleEditar={openEscopo}
                        handleExcluir={ExcluirEscopo}
                        handleOrdenar={handleOrdenar}
                        escopos={escopos}
                        ordenacao={ordenacao}
                        salvarEscopoDefault={salvarEscopoDefault}
                    />
                </div>
            )}
        </>
    );
}
