import React from 'react';
import { Box, Typography } from '@mui/material';

export const FlagStatus = ({ color, text }) => {
    const textColor = color?.includes('warning') ? 'var(--primary-dark-color)' : 'var(--white-color)';
    return (
        <Box
            sx={{
                bgcolor: color,
                borderRadius: 50,
                textAlign: 'center',
                px: 1,
                boxSizing: 'border-box',
                color: textColor,
            }}
        >
            <Typography align="center" variant="caption" sx={{ fontSize: '0.7rem' }}>
                {text}
            </Typography>
        </Box>
    );
};
