import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';

export const MenuCardItem = ({
    campanhaItens,
    creatorIdentify,
    handleDuplicarCampanhaItem,
    handleDeletarCampanhaItens,
    setEditMode,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeCreator, setActiveCreator] = useState(null);

    const handleOnEditCampanhaCreator = (creatorIdentify) => {
        if (creatorIdentify) {
            setEditMode({
                state: true,
                creatorIdentify,
            });
        }
    };

    const handleDeleteCampanhaCreator = async (creatorIdentify) => {
        const itemsToDelete = campanhaItens?.filter((item) => item.creatorIdentify === creatorIdentify);
        const idsToDelete = itemsToDelete?.map((item) => item.id);

        await handleDeletarCampanhaItens(idsToDelete);
    };

    const openMenu = (event, creatorId) => {
        setAnchorEl(event.currentTarget);
        setActiveCreator(creatorId);
        event.stopPropagation();
    };

    const closeMenu = () => {
        setAnchorEl(null);
        setActiveCreator(null);
    };
    return (
        <>
            <IconButton aria-label="settings" onClick={(e) => openMenu(e, creatorIdentify)}>
                <MoreVert />
            </IconButton>
            <Menu anchorEl={anchorEl} open={activeCreator === creatorIdentify} onClose={closeMenu}>
                <MenuItem
                    onClick={() => {
                        handleDuplicarCampanhaItem(creatorIdentify, campanhaItens);
                    }}
                >
                    Duplicar
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleOnEditCampanhaCreator(creatorIdentify);
                    }}
                >
                    Editar
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        handleDeleteCampanhaCreator(creatorIdentify);
                    }}
                >
                    Excluir
                </MenuItem>
            </Menu>
        </>
    );
};
