import { useEffect, useState } from 'react';
import { Avatar, Box, Button, InputLabel, TextField, Typography } from '@mui/material';
import { VideoSettings } from '@mui/icons-material';
import { escopoVazio } from '../../data/Interfaces';
import { ButtonFL } from '../../components/ButtonFL';

export const TemplateDados = ({ _handleSubmit, template }) => {
    const [form, setForm] = useState({
        titulo: template.titulo || '',
    });

    const handleSubmit = async (event) => {
        event.preventDefault();

        const handleTemplate = {
            ...template,
            titulo: form.titulo,
        };

        if (!handleTemplate.escopos || handleTemplate.escopos.length == 0) {
            handleTemplate.escopos = [escopoVazio];
        }

        _handleSubmit(handleTemplate);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setForm({
            ...form,
            [name]: value,
        });
    };

    useEffect(() => {
        if (template) {
            setForm({
                titulo: template.titulo || '',
            });
        }
    }, [template]);

    return (
        <>
            <Avatar
                sx={{
                    m: 1,
                    mt: 3,
                    bgcolor: 'var(--primary-color)',
                }}
            >
                <VideoSettings />
            </Avatar>

            <Typography component="h1" variant="h5" align="center">
                {!template.id ? 'Adicionar Escopo' : 'Alterar Escopo'}
            </Typography>

            <Box component="form" onSubmit={handleSubmit} sx={{ boxSizing: 'border-box', width: '100%', p: 4 }}>
                <InputLabel>Título</InputLabel>
                <TextField
                    required
                    fullWidth
                    name="titulo"
                    id="titulo"
                    value={form.titulo}
                    onChange={handleInputChange}
                    autoComplete="Título da Escopo"
                />

                {!template?.id ? (
                    <ButtonFL
                        type="submit"
                        fullWidth
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleSubmit}
                        textKey="Continuar"
                    />
                ) : (
                    <>
                        <ButtonFL
                            type="submit"
                            fullWidth
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleSubmit}
                            textKey="Salvar Título"
                        />
                    </>
                )}
            </Box>
        </>
    );
};
