import { useEffect, useState } from 'react';

import { AddCircle } from '@mui/icons-material';
import { ButtonFL } from '../../components/ButtonFL';

import styles from '../../_assets/css/generic.module.css';
import { mensagemVazio, showMessage } from '../../data/Interfaces';
import { Excluir, Get, Patch, Post } from '../../data/Verbs';
import { Loader } from '../../components/Loader';
import { Alert } from '../../components/common/Alert';
import { Creator } from './Creator';
import { CreatorsSearch } from './CreatorSearch';
import { CreatorsList } from './CreatorsList';
import { CreatorsMessage } from './CreatorsMessage';
import { CreatorsToExcel } from './CreatorsToExcel';
import { useError } from '../../contexts/ErrorContext';

export function Creators() {
    const [query, setQuery] = useState('');
    const [ordenacao, setOrdenacao] = useState('nome_asc');
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState(mensagemVazio);
    const [creators, setCreators] = useState([]);
    const [abreCreator, setAbreCreator] = useState({
        status: false,
        creator: {
            id: '0',
        },
    });
    const { showError } = useError();

    const salvarCreator = async (formToSubmit, creatorId) => {
        setIsLoading(true);
        const url = `${process.env.REACT_APP_API_URL}/creators`;
        let message = 'Creator inserido com sucesso';
        try {
            if (creatorId === '0') {
                await Post(url, formToSubmit);
            } else {
                await Patch(`${url}/${creatorId}`, formToSubmit);
                message = 'Creator alterado com sucesso';
            }

            closeCreator();
            showMessage(
                {
                    variant: 'success',
                    message,
                },
                setMessage,
            );
        } catch (error) {
            closeCreator();
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const ExcluirCreator = async (creator) => {
        setIsLoading(true);

        try {
            await Excluir(`${process.env.REACT_APP_API_URL}/creators/${creator.id}`);

            closeCreator();
            showMessage(
                {
                    variant: 'warning',
                    message: 'Creator excluido com sucesso',
                },
                setMessage,
            );
        } catch (error) {
            closeCreator();
            showError(error);
        }
    };

    const handleBusca = (_query) => {
        setIsLoading(true);

        setQuery(_query);
    };

    const openCreator = (_creator) => {
        setIsLoading(true);

        setAbreCreator({
            status: true,
            creator: _creator,
        });
    };

    const closeCreator = () => {
        setIsLoading(true);

        setAbreCreator({
            status: false,
            creator: {
                id: '0',
            },
        });
    };

    const handleOrdenar = (campo) => {
        setIsLoading(true);

        if (ordenacao === `${campo}_asc`) {
            setOrdenacao(`${campo}_desc`);
        } else {
            setOrdenacao(`${campo}_asc`);
        }
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const data =
                    query === ''
                        ? []
                        : await Get(`${process.env.REACT_APP_API_URL}/creators?query=${query}&sort=${ordenacao}`);

                setCreators(data);
            } catch (error) {
                showError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUsers();
    }, [query, ordenacao, abreCreator]);

    return (
        <>
            <div className={styles.container}>
                {message.message && <Alert variant={message.variant} message={message.message} />}
            </div>

            {isLoading && <Loader />}

            {abreCreator.status ? (
                <Creator creator={abreCreator.creator} handleSalvar={salvarCreator} handleFechar={closeCreator} />
            ) : (
                <div className={styles.container}>
                    <CreatorsSearch
                        query={query}
                        handleAdicionar={
                            <ButtonFL
                                onClick={() =>
                                    openCreator({
                                        id: '0',
                                    })
                                }
                                cliente={abreCreator.cliente}
                                variant="contained"
                                startIcon={<AddCircle />}
                                sx={{ mt: 1, mb: 1 }}
                                textKey="Adicionar"
                            />
                        }
                        handleBusca={handleBusca}
                    />

                    <CreatorsMessage
                        message={
                            query === ''
                                ? 'Para pesquisar creators, utilize a busca acima'
                                : `Listando ${creators.length} creators para a sua busca: ${query}`
                        }
                    />

                    {query && (
                        <>
                            <CreatorsList
                                handleEditar={openCreator}
                                handleExcluir={ExcluirCreator}
                                handleOrdenar={handleOrdenar}
                                creators={creators}
                                ordenacao={ordenacao}
                            />
                        </>
                    )}

                    <CreatorsToExcel />
                </div>
            )}
        </>
    );
}
