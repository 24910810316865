import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import LinkIcon from '@mui/icons-material/Link';

import { ButtonFL } from '../../components/ButtonFL';

export const FileDownload = ({ fileUrl, typeFile }) => {
    const isDocument = typeFile === 'document';

    const handleDownload = () => {
        let fileUrlWithHttp = fileUrl;
        if (!fileUrl.startsWith('http://') && !fileUrl.startsWith('https://')) {
            fileUrlWithHttp = 'http://' + fileUrl;
        }
        const openBlank = isDocument ? null : ' _blank';
        window.open(fileUrlWithHttp, openBlank);
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p={6}
            sx={{
                bgcolor: 'var(--primary-color)',
                borderRadius: 10,
                width: '70%',
                height: '50vh',
                color: 'var(--white-color)',
            }}
        >
            {isDocument ? (
                <CloudDownloadIcon sx={{ fontSize: 100, mb: 5 }} />
            ) : (
                <LinkIcon sx={{ fontSize: 100, mb: 5 }} />
            )}
            <Box sx={{ width: { xs: '30vh', md: '50vh', wordWrap: 'break-word' }, textAlign: 'center' }}>
                <Typography variant="h6" gutterBottom sx={{ mb: 5 }}>
                    {isDocument
                        ? 'Clique no botão abaixo para fazer o download do arquivo'
                        : 'Clique no botão abaixo para abrir o link.'}
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ mb: 5 }}>
                    {fileUrl}
                </Typography>
                <ButtonFL
                    textKey={isDocument ? 'Download do Arquivo' : 'Abrir o link'}
                    startIcon={isDocument ? <CloudDownloadIcon /> : <LinkIcon />}
                    bgcolor={'var(--white-color)'}
                    textColor="var(--primary-dark-color)"
                    bgColorHover={'var(--grey-100-color)'}
                    onClick={handleDownload}
                    fullWidth={'fullWidth'}
                />
            </Box>
        </Box>
    );
};
