import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase';
import { apiSendMessageToSlack } from '../../services';

/**
 * Log de erro.
 *
 * @param {string} code - Código do erro.
 * @param {string} message - Descrição do erro.
 */

export async function logError(code, message) {
    try {
        // logEvent(analytics, 'exception', {
        //     description: message,
        //     code,
        // });

        const slackMessage = {
            text: `${message} - Admin`,
        };

        await apiSendMessageToSlack(code, slackMessage);
    } catch (error) {
        console.log('Erro ao enviar mensagem para o Slack:', error);
    }
}
