import React, { useState } from 'react';

import { AddCircle, Check, HighlightOff, Save } from '@mui/icons-material';
import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
} from '@mui/material';
import { mensagemVazio, showMessage } from '../../../data/Interfaces';
import { Alert } from '../../../components/common/Alert';
import { Categorias } from '../../../components/common/Categorias';
import { EscopoCreator } from './EscopoCreator';
import { EscopoData } from './EscopoData';

export const Escopo = ({ template, creators, escopo, handleSaveEscopo, handleDeleteEscopo }) => {
    const [titulo, setTitulo] = useState(escopo.titulo || '');
    const [briefing, setBriefing] = useState(escopo.briefing || '');
    const [message, setMessage] = useState(mensagemVazio);
    const [item, setItem] = useState({
        id: '',
        quantidade: '',
        categoria: '',
        subcategoria: '',
        titulo: '',
    });

    const handleTitulo = async () => {
        const handleEscopo = {
            ...escopo,
            titulo,
            briefing,
        };

        await handleSaveEscopo(handleEscopo);
    };

    const handleSaveEscopoItem = () => {
        if (item.quantidade <= 0) {
            showMessage({ variant: 'warning', message: 'O campo Quantidade é obrigatório' }, setMessage);
            return false;
        }

        if (item.categoria == '') {
            showMessage({ variant: 'warning', message: 'O campo Entrega é obrigatório' }, setMessage);
            return false;
        }

        if (item.id) {
            handleEditEscopoItem();
        } else {
            handleAddEscopoItem();
        }

        setItem({
            id: '',
            quantidade: '',
            categoria: '',
            subcategoria: '',
            titulo: '',
        });
    };

    const handleAddEscopoItem = () => {
        const handleEscopo = {
            ...escopo,
        };

        handleEscopo.data.push({
            categoria: item.categoria,
            subcategoria: item.subcategoria,
            titulo: item.titulo,
            quantidade: Number(item.quantidade),
        });

        handleSaveEscopo(handleEscopo);
    };

    const handleEditEscopoItem = () => {
        const itemIndex = escopo.data.findIndex((dataItem) => dataItem.id == item.id);

        if (itemIndex !== -1) {
            const handleEscopo = { ...escopo };
            const editedItem = { ...escopo.data[itemIndex] };

            editedItem.categoria = item.categoria;
            editedItem.subcategoria = item.subcategoria;
            editedItem.titulo = item.titulo;
            editedItem.quantidade = Number(item.quantidade);

            handleEscopo.data[itemIndex] = editedItem;

            handleSaveEscopo(handleEscopo);
        }
    };

    const handleOnEditEscopoItem = (escopoItem) => {
        setItem({
            id: escopoItem?.id || '',
            quantidade: escopoItem?.quantidade || '',
            categoria: escopoItem?.categoria || '',
            subcategoria: escopoItem?.subcategoria || '',
            titulo: escopoItem?.titulo || '',
        });
    };

    const handleDeleteEscopoItem = (escopoItem) => {
        const itemIndex = escopo.data.findIndex((dataItem) => dataItem.id == escopoItem.id);

        if (itemIndex !== -1) {
            const updatedEscopo = { ...escopo };

            updatedEscopo.data.splice(itemIndex, 1);

            handleSaveEscopo(updatedEscopo);
        }
    };

    const handleAddEscopoCreator = ({ creator, valor }) => {
        const handleEscopo = {
            ...escopo,
        };

        if (!handleEscopo.creators || !Array.isArray(handleEscopo.creators)) {
            handleEscopo.creators = [];
        }

        handleEscopo.creators.push({
            creator: creator,
            valor: valor,
        });

        handleSaveEscopo(handleEscopo);
    };

    const handleDeleteEscopoCreator = (escopoCreator) => {
        const creatorIndex = escopo.creators.findIndex((dataCreator) => dataCreator.id == escopoCreator.id);

        if (creatorIndex !== -1) {
            const updatedEscopo = { ...escopo };

            updatedEscopo.creators.splice(creatorIndex, 1);

            handleSaveEscopo(updatedEscopo);
        }
    };

    const handleAddBriefing = (escopo) => {
        setBriefing(' ');

        escopo.briefing = briefing;
    };

    const handleDeleteBriefing = (escopo) => {
        setBriefing('');

        escopo.briefing = briefing;

        handleSaveEscopo(escopo);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setItem({
            ...item,
            [name]: value,
        });
    };

    return (
        <>
            <Box
                sx={{
                    borderRadius: '10px',
                    overflow: 'hidden',
                }}
            >
                <TextField
                    id="escopo-titulo"
                    label="Digite o nome do escopo"
                    variant="filled"
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                    onBlur={handleTitulo}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={() => handleDeleteEscopo(escopo)} aria-label="Excluir">
                                <HighlightOff />
                            </IconButton>
                        ),
                    }}
                    sx={{
                        marginTop: 2,
                    }}
                />

                <Grid container spacing={0} style={{ padding: 10, backgroundColor: 'var(--grey-50-color)' }}>
                    <Grid item sm={12}>
                        {!briefing ? (
                            <Button
                                onClick={() => handleAddBriefing(escopo)}
                                variant="filled"
                                aria-label="Adicionar Briefing"
                                startIcon={<AddCircle />}
                            >
                                Adicionar Briefing
                            </Button>
                        ) : (
                            <FormControl fullWidth variant="outlined" margin="normal">
                                <InputLabel htmlFor="briefing">Briefing</InputLabel>
                                <OutlinedInput
                                    multiline
                                    minRows={2}
                                    id="escopo-briefing"
                                    label="Digite o nome do escopo"
                                    variant="filled"
                                    value={briefing}
                                    onChange={(e) => setBriefing(e.target.value)}
                                    endAdornment={
                                        <Box sx={{ display: 'flex' }}>
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleTitulo} aria-label="Check">
                                                    <Check style={{ color: 'green' }} />
                                                </IconButton>
                                            </InputAdornment>
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => handleDeleteBriefing(escopo)}
                                                    aria-label="Excluir Briefing"
                                                >
                                                    <HighlightOff style={{ color: 'red' }} />
                                                </IconButton>
                                            </InputAdornment>
                                        </Box>
                                    }
                                />
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item sm={12}>
                        <p>Selecione a entrega e clique no botão +</p>

                        {message.message && <Alert variant={message.variant} message={message.message} />}

                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <TextField
                                    id="filled-number"
                                    label="Qtde"
                                    type="number"
                                    variant="filled"
                                    name="quantidade"
                                    value={item.quantidade}
                                    onChange={handleInputChange}
                                    size="small"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <Categorias item={item} setItem={setItem} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    label="Título"
                                    type="text"
                                    variant="filled"
                                    name="titulo"
                                    value={item.titulo}
                                    onChange={handleInputChange}
                                    size="small"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid
                                item
                                xs={1}
                                style={{
                                    padding: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <IconButton
                                    onClick={() => handleSaveEscopoItem()}
                                    style={{
                                        padding: 0,
                                        margin: 'auto 0 0 0',
                                        fontSize: '3rem',
                                        color: 'var(--primary-color)',
                                    }}
                                >
                                    {item.id ? <Save fontSize="3rem" /> : <AddCircle fontSize="3rem" />}
                                </IconButton>
                            </Grid>
                        </Grid>

                        <EscopoData
                            escopo={escopo}
                            handleOnEditEscopoItem={handleOnEditEscopoItem}
                            handleDeleteEscopoItem={handleDeleteEscopoItem}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <p>Selecione um creator por vez e clique no botão +</p>
                        <EscopoCreator
                            creators={creators}
                            escopo={escopo}
                            handleAddEscopoCreator={handleAddEscopoCreator}
                            handleDeleteEscopoCreator={handleDeleteEscopoCreator}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
