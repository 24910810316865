import { create } from 'zustand';
import { apiGetUnread } from '../services/apiGetUnread';

export const useNotificationStore = create((set, get) => ({
    notifications: [],

    fetchNotifications: async () => {
        const unreadChats = await apiGetUnread();
        set({ notifications: unreadChats });
    },

    getNotificationsByCampanhaItem: (campanhaItem) => {
        const { notifications } = get();
        return notifications.filter((n) => n.idcampanhaitem === campanhaItem).length;
    },

    getNotificationsByContext: (contexto) => {
        const { notifications } = get();
        return notifications.filter((n) => n.contexto == contexto).length;
    },

    getNotificationsByCreator: async (campanha, contexto) => {
        const { notifications } = get();
        const filteredNotifications = notifications.filter(
            (notification) => notification.idcampanha == campanha && notification.contexto == contexto,
        );

        return filteredNotifications.reduce((acc, notification) => {
            const creator = notification.idcreator;
            if (!acc[creator]) {
                acc[creator] = 0;
            }

            acc[creator] += 1;

            return acc;
        }, {});
    },

    clearNotifications: () => set({ notifications: [] }),
}));
