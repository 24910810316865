import { Post } from '../data/Verbs';

export const apiPostCreatorsBulk = async (creators) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/creators/bulk`, creators);
        return response;
    } catch (error) {
        throw error;
    }
};
