import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { menus } from '../data/Menus';
import { useAuthStore } from '../store';

const ListItems = ({ open }) => {
    const user = useAuthStore((state) => state.user);
    const listItemIconStyleClosed = {
        padding: '6px',
    };

    const listItemIconStyle = {
        minWidth: '35px',
    };

    const defaultTextStyle = {
        fontWeight: 'normal',
        color: 'inherit',
    };

    const hoverStyles = {
        '&:hover': {
            backgroundColor: 'var(--primary-color)',
        },
    };

    const hoverTextStyle = {
        '&:hover': {
            fontWeight: 'bold',
            color: 'var(--white-color)',
        },
    };

    return (
        <List component="nav">
            {menus.map(
                (menu, index) =>
                    user.acesso >= menu.accessLevel && (
                        <ListItemButton key={index} to={menu.link} sx={hoverStyles}>
                            <ListItemIcon
                                title={menu.title}
                                style={open === true ? listItemIconStyle : listItemIconStyleClosed}
                            >
                                {menu.icon && <menu.icon />}
                            </ListItemIcon>
                            {open === true && (
                                <ListItemText
                                    primary={menu.title}
                                    sx={{
                                        ...defaultTextStyle,
                                        ...hoverTextStyle,
                                    }}
                                />
                            )}
                        </ListItemButton>
                    ),
            )}
        </List>
    );
};

export default ListItems;
