import React, { useState } from 'react';
import {
    Box,
    Typography,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

export const DeletableItem = ({ item, handleDeleteCampanhaItem }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (confirm) => {
        setOpen(false);
        if (confirm) {
            handleDeleteCampanhaItem(item);
        }
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                    justifyContent: 'end',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'end',
                        bgcolor: 'var(--error-color)',
                        p: 1,
                        borderRadius: 3,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            color: 'var(--white-color)',
                        }}
                    >
                        <Typography variant="body2">Excluir </Typography>
                        <IconButton onClick={handleClickOpen} aria-label="edit-date">
                            <Delete sx={{ fontSize: 18, color: 'var(--white-color)' }} />
                        </IconButton>
                    </Box>
                </Box>
            </Box>

            <Dialog
                open={open}
                onClose={() => handleClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Confirmação de Exclusão'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Você confirma que quer excluir essa entrega?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)} color="primary">
                        Não
                    </Button>
                    <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                        Sim
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
