import { Alert as AlertComponent, CardHeader, Grid, Box, IconButton, Badge } from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { AddCircleOutline } from '@mui/icons-material';
import { Excluir, Patch } from '../../data/Verbs';
import { CampanhaItensList } from '../../pages/campanhas/CampanhaItensList';
import { EscopoCreatorModal } from '../../pages/templates/escopos/EscopoCreatorModal';
import { MenuCardItem } from '../../components/MenuCardItem';
import { CampaignCardsColumn } from '../../components/CampaignCardsColumn';
import { ChatModal } from '../ChatModal';
import { AvatarFL } from '../../components/AvatarFL';
import { ButtonFL } from '../ButtonFL';
import { useNotificationStore } from '../../store';
import { Loader } from '../Loader';
import { handleClearStorage } from '../../utils/handleStorage.utils';
import { placeholder } from '../../utils';
import { useError } from '../../contexts/ErrorContext';
import { IcampanhasItensStatus } from '../../data/Interfaces';
import CustomBadge from '../common/CustomBadge';

export const ScopeCreatosCard = ({
    groupedItems,
    filtroEscopoTitulo,
    isPendingFilterActive,
    campanha,
    campanhaItens,
    workflows,
    etapas,
    handleSalvarCampanhaItem,
    updateCampanhaItem,
    handleDuplicarCampanhaItem,
    handleDeletarCampanhaItens,
    handleChangeCreator,
    creators,
    tab,
    updated,
    setUpdated,
}) => {
    const [editMode, setEditMode] = useState({
        state: false,
        creatorIdentify: null,
    });
    const [openChat, setOpenChat] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const { showError } = useError();

    const { fetchNotifications, notifications } = useNotificationStore((state) => ({
        fetchNotifications: state.fetchNotifications,
        notifications: state.notifications,
    }));

    const creatorsFilteredWithPlaceholder = useMemo(() => {
        const creatorsFiltered = creators.filter((creator) => {
            return !campanhaItens.some((item) => item.creator == creator.id);
        });
        return [{ id: null, nome: placeholder }, ...creatorsFiltered];
    }, [creators, campanhaItens]);

    const handleDeleteCampanhaItem = async (item) => {
        try {
            await Excluir(`${process.env.REACT_APP_API_URL}/campanhasItens/${item.id}`);
            fetchNotifications();
            setUpdated((prevUpdated) => !prevUpdated);
        } catch (error) {
            showError(error);
        }
    };

    const handleOnAddEscopo = async (item) => {
        setIsLoading(true);

        let campanhaItemModelo = { ...campanhaItens[0], ...item[0] };
        Object.keys(campanhaItemModelo).forEach((key) => {
            if (
                [
                    'id',
                    'created_at',
                    'updated_at',
                    'etapa',
                    'linhaPlanilha',
                    'escopoBriefing',
                    'roteiroFile',
                    'roteiroTexto',
                    'roteiroUrl',
                    'sobeLink',
                    'status',
                    'subcategoria',
                    'idVideo',
                    'videoFile',
                    'url',
                    'valor',
                    'vertical',
                    'verticalLink',
                ].includes(key)
            ) {
                delete campanhaItemModelo[key];
            }
        });

        await handleSalvarCampanhaItem(campanhaItemModelo);
    };

    const handleEditCampanhaItem = async (updatedItem) => {
        const { reprovaMensagem, ...updatedItemToUpdate } = updatedItem;
        const selectedIndex = campanhaItens.findIndex((item) => item.id === updatedItemToUpdate.id);

        if (selectedIndex !== -1) {
            try {
                await Patch(
                    `${process.env.REACT_APP_API_URL}/campanhasItens/${updatedItemToUpdate.id}`,
                    updatedItemToUpdate,
                );
                setUpdated((prevUpdated) => !prevUpdated);
            } catch (error) {
                showError(error);
            }
        }
    };

    const handleSaveEscopoCreator = async ({ creator, valor, creatorIdentify, escopoBriefing }) => {
        setIsLoading(true);

        setEditMode({
            state: false,
            creatorIdentify: null,
        });

        const creatorName = creators.find((option) => option.id === creator)?.nome || null;

        await handleChangeCreator({ creatorIdentify, creator, creatorName, valor, escopoBriefing });
    };

    const toggleChat = (item) => {
        const newItem = {
            ...campanha,
            creator: item.creator,
            creatorName: item.creatorName,
        };

        if (item.creator) {
            setSelectedItem(newItem);
            setOpenChat(!openChat);
        } else {
            setSelectedItem(null);
            setOpenChat(false);
        }

        handleClearStorage();
    };

    const filteredGroupedItems = useMemo(() => {
        if (Object.keys(groupedItems)?.length > 0) {
            const filteredItems = Object.keys(groupedItems).filter((creatorIdentify) => {
                if (isPendingFilterActive) {
                    return groupedItems[creatorIdentify]?.items.some(
                        (item) =>
                            item.status === IcampanhasItensStatus.WAITING ||
                            item.status === IcampanhasItensStatus.WAITING_CLIENT,
                    );
                } else {
                    return (
                        !filtroEscopoTitulo ||
                        groupedItems[creatorIdentify].items.some((item) => item.escopoTitulo === filtroEscopoTitulo)
                    );
                }
            });

            return filteredItems.sort((a, b) => {
                const creatorNameA = groupedItems[a].items[0].creatorName;
                const creatorNameB = groupedItems[b].items[0].creatorName;
                if (creatorNameA < creatorNameB) return -1;
                if (creatorNameA > creatorNameB) return 1;

                return 0;
            });
        }
        return [];
    }, [groupedItems, filtroEscopoTitulo, isPendingFilterActive]);

    useEffect(() => {
        setIsLoading(false);
    }, [updated, groupedItems]);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {groupedItems && Object.keys(groupedItems).length > 0 && editMode.state ? (
                        <EscopoCreatorModal
                            open={true}
                            creators={creators}
                            creatorsFiltered={creatorsFilteredWithPlaceholder}
                            creatorIdentify={editMode.creatorIdentify}
                            creator={Number(groupedItems[editMode.creatorIdentify].creator) || 0}
                            valor={groupedItems[editMode.creatorIdentify].valor || null}
                            campanhaBriefing={groupedItems[editMode.creatorIdentify].campanhaBriefing || ''}
                            handleSaveEscopoCreator={handleSaveEscopoCreator}
                            onClose={() => setEditMode({ state: false, creatorIdentify: null })}
                        />
                    ) : null}
                    <Box
                        sx={{
                            display: 'flex',
                            mr: { md: 20 },
                            overflow: { xs: 'auto', md: 'unset' },
                            width: '100%',
                        }}
                    >
                        {!filteredGroupedItems ? (
                            <Grid item xs={12}>
                                <Loader />
                            </Grid>
                        ) : filteredGroupedItems.length === 0 ? (
                            <Grid item xs={12}>
                                <AlertComponent severity="warning">Nenhum item encontrado</AlertComponent>
                            </Grid>
                        ) : (
                            filteredGroupedItems.map((creatorIdentify, index) => (
                                <CampaignCardsColumn
                                    key={index}
                                    groupedItems={groupedItems}
                                    creatorIdentify={creatorIdentify}
                                    toggleChat={toggleChat}
                                >
                                    <CardHeader
                                        avatar={
                                            <AvatarFL
                                                name={
                                                    groupedItems[creatorIdentify].creatorName
                                                        ? groupedItems[creatorIdentify].creatorName
                                                        : 'C'
                                                }
                                            />
                                        }
                                        title={`${
                                            groupedItems[creatorIdentify].creatorName
                                                ? groupedItems[creatorIdentify].creatorName
                                                : placeholder
                                        }`}
                                        subheader={groupedItems[creatorIdentify].escopoTitulo}
                                        action={
                                            <>
                                                <CustomBadge
                                                    badgeContent={groupedItems[creatorIdentify].unreadMessages}
                                                    Icon={ChatBubbleIcon}
                                                    buttonProps={{
                                                        size: 'small',
                                                        color: 'var(--primary-color)',
                                                        onClick: () => toggleChat(groupedItems[creatorIdentify]),
                                                    }}
                                                />

                                                <MenuCardItem
                                                    campanhaItens={campanhaItens}
                                                    creatorIdentify={creatorIdentify}
                                                    handleDeletarCampanhaItens={handleDeletarCampanhaItens}
                                                    handleDuplicarCampanhaItem={handleDuplicarCampanhaItem}
                                                    setUpdated={setUpdated}
                                                    setEditMode={setEditMode}
                                                />
                                            </>
                                        }
                                    />
                                    <CampanhaItensList
                                        key={index}
                                        campanhaItens={groupedItems[creatorIdentify].items}
                                        items={groupedItems[creatorIdentify].items}
                                        campanha={campanha}
                                        etapas={etapas}
                                        workflows={workflows}
                                        handleEditCampanhaItem={handleEditCampanhaItem}
                                        handleDeleteCampanhaItem={handleDeleteCampanhaItem}
                                        handleDuplicarCampanhaItem={handleDuplicarCampanhaItem}
                                        setUpdated={setUpdated}
                                        tab={tab}
                                        updateCampanhaItem={updateCampanhaItem}
                                    />
                                    <Box sx={{ margin: 1 }}>
                                        <ButtonFL
                                            startIcon={<AddCircleOutline />}
                                            textColor="var(--primary-dark-color)"
                                            bgcolor="var(--grey-300-color)"
                                            bgColorHover="var(--grey-400-color)"
                                            textKey="Entrega"
                                            onClick={() => handleOnAddEscopo(groupedItems[creatorIdentify].items)}
                                            fullWidth
                                        />
                                    </Box>
                                </CampaignCardsColumn>
                            ))
                        )}
                    </Box>

                    <ChatModal
                        headerTitle={selectedItem?.creatorName}
                        item={selectedItem}
                        creator={selectedItem?.creator}
                        openChat={openChat}
                        toggleDrawer={toggleChat}
                        contexto="ADMIN_CREATOR_CAMPANHA"
                    />
                </>
            )}
        </>
    );
};
