import { Post } from '../data/Verbs';

export const apiSendMessageToSlack = async (code, slackMessage) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/logError`, {
            code,
            message: JSON.stringify(slackMessage),
        });

        return response;
    } catch (error) {
        throw error;
    }
};
