import { useEffect, useState } from 'react';

import { AddCircle } from '@mui/icons-material';
import { ButtonFL } from '../../components/ButtonFL';

import styles from '../../_assets/css/generic.module.css';
import { mensagemVazio, showMessage } from '../../data/Interfaces';
import { Excluir, Get, Patch, Post } from '../../data/Verbs';
import { Alert } from '../../components/common/Alert';
import { Loader } from '../../components/Loader';
import { Usuario } from './Usuario';
import { UsuariosLista } from './UsuariosLista';
import { UsuariosSearch } from './UsuariosSearch';

export function Usuarios() {
    const queryFLS = localStorage.getItem('usuarios-query');
    const clienteFLS = localStorage.getItem('usuarios-cliente');

    const initialQuery = queryFLS !== null && queryFLS !== 'undefined' ? queryFLS : '';
    const initialCliente = clienteFLS !== null && clienteFLS !== 'undefined' ? clienteFLS : '';

    const [query, setQuery] = useState(initialQuery);
    const [cliente, setCliente] = useState(initialCliente);

    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState(mensagemVazio);
    const [usuarios, setUsuarios] = useState([]);
    const [abreUsuario, setAbreUsuario] = useState({
        status: false,
        usuario: {
            id: '0',
        },
    });

    const salvarUsuario = async (usuario) => {
        const url = `${process.env.REACT_APP_API_URL}/usuarios`;
        let message = 'Usuário inserido com sucesso';

        try {
            if (usuario.id === '0') {
                delete usuario.id;

                await Post(url, usuario);
            } else {
                await Patch(`${url}/${usuario.id}`, usuario);
                message = 'Usuário alterado com sucesso';
            }

            closeUsuario();
            showMessage(
                {
                    variant: 'success',
                    message,
                },
                setMessage,
            );
        } catch (error) {
            closeUsuario();
            showMessage(
                {
                    variant: 'warning',
                    message: error.message,
                },
                setMessage,
            );
        }
    };

    const ExcluirUsuario = async (usuario) => {
        try {
            await Excluir(`${process.env.REACT_APP_API_URL}/usuarios/${usuario.id}`);

            closeUsuario();
            showMessage(
                {
                    variant: 'warning',
                    message: 'Usuário excluido com sucesso',
                },
                setMessage,
            );
        } catch (error) {
            closeUsuario();

            showMessage(
                {
                    variant: 'error',
                    message: error.message,
                },
                setMessage,
            );
        }
    };

    const handleBusca = (_query, _cliente) => {
        setQuery(_query);
        setCliente(_cliente);
    };

    const openUsuario = (_usuario) => {
        setAbreUsuario({
            status: true,
            usuario: _usuario,
        });
    };

    const closeUsuario = () => {
        setAbreUsuario({
            status: false,
            usuario: {
                id: '0',
            },
        });
    };

    useEffect(() => {
        const fetchUsers = async () => {
            let url = `${process.env.REACT_APP_API_URL}/usuarios`;
            let comp = '?';

            if (query !== '') {
                url += `${comp}query=${query}`;
                comp = '&';
            }

            if (cliente != undefined) {
                url += `${comp}cliente=${cliente}`;
                comp = '&';
            }

            const data = await Get(url);

            setUsuarios(data);
            setIsLoading(false);
        };

        fetchUsers();
    }, [abreUsuario, query, cliente]);

    useEffect(() => {
        if (query !== undefined) {
            localStorage.setItem('usuarios-query', query);
        }
    }, [query]);

    useEffect(() => {
        if (cliente !== undefined) {
            localStorage.setItem('usuarios-cliente', cliente);
        }
    }, [cliente]);

    return (
        <>
            <div className={styles.container}>
                {message.message && <Alert variant={message.variant} message={message.message} />}
            </div>

            <div className={styles.container}>
                {isLoading ? (
                    <Loader />
                ) : abreUsuario.status ? (
                    <Usuario
                        usuario={abreUsuario.usuario}
                        className={styles.container}
                        handleSalvar={salvarUsuario}
                        handleFechar={closeUsuario}
                    />
                ) : (
                    <>
                        <UsuariosSearch
                            query={query}
                            cliente={cliente}
                            handleAdicionar={
                                <ButtonFL
                                    onClick={() =>
                                        openUsuario({
                                            id: '0',
                                        })
                                    }
                                    usuario={abreUsuario.usuario}
                                    variant="contained"
                                    startIcon={<AddCircle />}
                                    sx={{ mt: 1, mb: 1 }}
                                    textKey="Adicionar"
                                />
                            }
                            handleBusca={handleBusca}
                        />

                        <UsuariosLista usuarios={usuarios} handleEditar={openUsuario} handleExcluir={ExcluirUsuario} />
                    </>
                )}
            </div>
        </>
    );
}
