import { useState } from 'react';

import { Search } from '@mui/icons-material';
import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';

export const ClientesSearch = ({ handleAdicionar, handleBusca, query }) => {
    const [busca, setBusca] = useState(query);

    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={4}>
                    {handleAdicionar}
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="cliente"
                        label="Busque por Cliente"
                        name="cliente"
                        autoComplete="cliente"
                        defaultValue={query}
                        onChange={(e) => setBusca(e.target.value)}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                handleBusca(busca);
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Buscar Cliente"
                                        size="small"
                                        onClick={() => handleBusca(busca)}
                                    >
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};
