import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Badge, CardHeader } from '@mui/material';
import { isBefore, isEqual } from 'date-fns';
import { FlagStatus } from '../FlagStatus';
import { FlagStage } from '../FlagStage';

import { getStatusStages, getIconByCategory, formatDate, placeholder, getStageCampain } from '../../utils';
import { useError } from '../../contexts/ErrorContext';

import { useNotificationStore } from '../../store';
import {
    handleClearStorage,
    handleOpenCampanhaItemFromNotification,
    storageCampanhaItem,
} from '../../utils/handleStorage.utils';
import { IcampanhasItensStatus } from '../../data/Interfaces';
import { Loader } from '../Loader';

export const CampainCard = ({ campain, handleOpenModal, tab, children }) => {
    const { notifications, getNotificationsByCampanhaItem } = useNotificationStore((state) => ({
        notifications: state.notifications,
        getNotificationsByCampanhaItem: state.getNotificationsByCampanhaItem,
    }));

    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const flagStatusData = getStatusStages(campain?.status);
    const completedDate = formatDate(campain?.completedDate);
    const completedDateRaw = new Date(campain?.completedDate);
    const currentDate = new Date();

    const stageCampain = getStageCampain(campain.etapa);

    const isPastDue =
        completedDate && (isBefore(completedDateRaw, currentDate) || isEqual(completedDateRaw, currentDate));
    const color =
        isPastDue && campain?.status !== IcampanhasItensStatus.COMPLETED ? 'var(--error-color)' : 'var(--black-color)';

    const icon = getIconByCategory(campain?.categoria);

    const { showError } = useError();

    useEffect(() => {
        try {
            const getCampaignInformation = async () => {
                const notificationsTotal = getNotificationsByCampanhaItem(campain.id);
                setTotal(notificationsTotal);
            };

            getCampaignInformation();
        } catch (error) {
            showError(error);
        }

        setIsLoading(false);
    }, [campain, notifications]);

    useEffect(() => {
        if (handleOpenCampanhaItemFromNotification() && storageCampanhaItem == campain.id) {
            handleOpenModal(campain);
            handleClearStorage();
        }

        setIsLoading(false);
    }, [total]);

    return (
        <>
            {isLoading && <Loader />}

            <Box key={campain.id} sx={{ padding: 1, cursor: 'pointer' }} onClick={() => handleOpenModal(campain)}>
                <Card
                    key={campain.id}
                    sx={{
                        boxShadow: 'none',
                        bgcolor: 'var(--grey-50-color)',
                        borderRadius: 3,
                        minHeight: '20vh',
                        width: { xs: '100%', sm: '260px', md: '300px' },
                    }}
                >
                    {tab !== 1 && (
                        <CardHeader
                            title={
                                <Typography
                                    sx={{
                                        fontSize: '0.9rem',
                                        color: 'white',
                                    }}
                                >
                                    {campain?.creatorName ? campain?.creatorName : placeholder}
                                </Typography>
                            }
                            sx={{
                                backgroundColor: 'var(--primary-color)',
                                padding: 1,
                                borderRadius: 1,
                                textAlign: 'center',
                            }}
                        />
                    )}

                    <CardContent>
                        <Box sx={{ mb: 2 }}>
                            <FlagStage color={stageCampain?.color} text={stageCampain?.text} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                mb: 2,
                            }}
                        >
                            {completedDate && <FlagStatus color={color} text={completedDate} />}
                            {flagStatusData && <FlagStatus color={flagStatusData?.color} text={flagStatusData?.text} />}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
                                    {icon}
                                    <Typography variant="body" sx={{ mx: 0.5 }}>
                                        {campain.titulo
                                            ? `${campain.categoria} - ${campain.subcategoria}`
                                            : campain.categoria}
                                    </Typography>
                                </Box>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    sx={{ mt: 1, wordBreak: 'break-word', overflowWrap: 'break-word' }}
                                >
                                    {campain.titulo || campain.subcategoria}
                                </Typography>
                            </Box>
                        </Box>
                    </CardContent>

                    {children}
                </Card>
            </Box>
        </>
    );
};
