import React from 'react';
import { Box } from '@mui/material';
import { GenericModal } from '../common/GenericModal';
import { Chats } from '../chat/Chats';

export const ChatModal = ({ item, creator, openChat, toggleDrawer, contexto, headerTitle }) => {
    return (
        <GenericModal open={openChat} onClose={toggleDrawer} bgColor={'var(--white-color)'}>
            <Box>
                <Chats
                    hasHeader
                    headerTitle={headerTitle}
                    title="Mensagens"
                    item={item}
                    contexto={contexto}
                    creator={creator}
                />
            </Box>
        </GenericModal>
    );
};
