import { Post } from '../data/Verbs';

export const apiPostReprovedCampaign = async (id, messageReproved) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/campanhasItens/reprovar/${id}`, {
            reprovacao: messageReproved,
        });
        return response;
    } catch (error) {
        throw error;
    }
};
