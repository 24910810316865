import { Cancel } from '@mui/icons-material';
import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { EscopoCreatorModal } from './EscopoCreatorModal';
import { ButtonFL } from '../../../components/ButtonFL';

export const EscopoCreator = ({ creators, escopo, handleAddEscopoCreator, handleDeleteEscopoCreator }) => {
    const [openCreator, setOpenCreator] = useState(false);

    const handleSaveEscopoCreator = ({ creator, valor, escopoBriefing: escopo }) => {
        handleAddEscopoCreator({ creator, valor, escopoBriefing: escopo });

        setOpenCreator(false);
    };

    const escopoCreators =
        escopo.creators && escopo.creators.length > 0
            ? escopo.creators
                  .map((item) => {
                      const matchedCreator = creators.find((creator) => creator.id === item.creator);
                      if (matchedCreator) {
                          return {
                              ...item,
                              nome: matchedCreator.nome,
                          };
                      }
                      return item;
                  })
                  .sort((a, b) => (a.nome && b.nome ? a.nome.localeCompare(b.nome) : 0))
            : [];

    const creatorsFiltered = creators.filter((creator) => {
        return !escopoCreators.some((item) => item.creator === creator.id);
    });

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ButtonFL fullWidth textKey="Adicionar Creator" onClick={() => setOpenCreator(true)} />
                </Grid>

                <EscopoCreatorModal
                    open={openCreator}
                    creators={escopo?.creators}
                    valor={escopo?.valor ?? 0}
                    escopoBriefing={escopo?.escopoBriefing ?? ''}
                    creatorsFiltered={creatorsFiltered}
                    handleSaveEscopoCreator={handleSaveEscopoCreator}
                    onClose={() => setOpenCreator(false)}
                />
            </Grid>

            {!escopoCreators ||
                (escopoCreators.length > 0 && (
                    <TableContainer sx={{ marginTop: 2 }}>
                        <Table size="small">
                            <TableBody>
                                {escopoCreators.map((creator, index) => {
                                    const creatorFound = creators.find((_creator) => _creator.id === creator.creator);

                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {creatorFound
                                                    ? `${creatorFound.nome} - ${creatorFound.email} - cachê R$ ${creator.valor}`
                                                    : `Creator não definido - cachê R$ ${creator.valor}`}
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton onClick={() => handleDeleteEscopoCreator(creator)}>
                                                    <Cancel />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ))}
        </>
    );
};
