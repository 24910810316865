import React from 'react';
import { Box, Typography } from '@mui/material';

export const SubtitleFL = ({ color, subtitle, subtitleSize = 'body2', iconSubtitle }) => {
    const styles = { display: 'flex', alignItems: 'center' };
    return (
        <Box
            sx={{
                color,
            }}
        >
            <Box sx={styles}>
                <Box sx={{ marginRight: 0.5 }}>{iconSubtitle && iconSubtitle}</Box>
                {subtitle && <Typography variant={subtitleSize}>{subtitle}</Typography>}
            </Box>
        </Box>
    );
};
