import { ArrowDownward, ArrowUpward, Delete, ModeEdit } from '@mui/icons-material';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

export const CampanhasList = ({ campanhas, handleExcluir, handleOrdenar, ordenacao }) => {
    const navigate = useNavigate();

    const editarCampanha = (campanha) => {
        navigate(`/campanhas/editar/${campanha.id}`);
    };

    const excluirCampanha = (campanha) => {
        handleExcluir(campanha);
    };

    const isOrdenadoAsc = (campo) => {
        return ordenacao === `${campo}_asc`;
    };

    const isOrdenadoDesc = (campo) => {
        return ordenacao === `${campo}_desc`;
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#424242',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const iconStyle = {
        fontSize: '14px',
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell
                            onClick={() => handleOrdenar('titulo')}
                            style={{
                                cursor: 'pointer',
                                fontWeight: isOrdenadoAsc('titulo') || isOrdenadoDesc('titulo') ? 'bold' : 'normal',
                            }}
                        >
                            Título
                            {isOrdenadoAsc('titulo') && <ArrowUpward style={iconStyle} />}{' '}
                            {isOrdenadoDesc('titulo') && <ArrowDownward style={iconStyle} />}
                        </StyledTableCell>
                        <StyledTableCell
                            onClick={() => handleOrdenar('cliente')}
                            style={{
                                cursor: 'pointer',
                                fontWeight: isOrdenadoAsc('cliente') || isOrdenadoDesc('cliente') ? 'bold' : 'normal',
                            }}
                        >
                            Cliente
                            {isOrdenadoAsc('cliente') && <ArrowUpward style={iconStyle} />}{' '}
                            {isOrdenadoDesc('cliente') && <ArrowDownward style={iconStyle} />}
                        </StyledTableCell>
                        <StyledTableCell
                            onClick={() => handleOrdenar('projeto')}
                            style={{
                                cursor: 'pointer',
                                fontWeight: isOrdenadoAsc('projeto') || isOrdenadoDesc('projeto') ? 'bold' : 'normal',
                            }}
                        >
                            Projeto
                            {isOrdenadoAsc('projeto') && <ArrowUpward style={iconStyle} />}{' '}
                            {isOrdenadoDesc('projeto') && <ArrowDownward style={iconStyle} />}
                        </StyledTableCell>
                        <StyledTableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {campanhas.map((campanha) => (
                        <TableRow
                            key={campanha.id}
                            className="nowrap"
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {campanha.titulo}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {campanha.clienteNome}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {campanha.projetoTitulo}
                            </TableCell>
                            <TableCell align="right">
                                <Button
                                    aria-label="Editar Campanha"
                                    size="small"
                                    onClick={() => editarCampanha(campanha)}
                                    sx={{ color: 'var(--primary-color)' }}
                                >
                                    <ModeEdit />
                                </Button>

                                <Button
                                    aria-label="delete"
                                    size="small"
                                    onClick={() => excluirCampanha(campanha)}
                                    sx={{ color: 'var(--primary-color)' }}
                                >
                                    <Delete fontSize="small" />
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
