import React, { useEffect } from 'react';
import { Routes } from './routes';
import { useAuthStore, useNotificationStore } from './store';

export default function App() {
    const fetchUser = useAuthStore((state) => state.fetchUser);
    const fetchNotifications = useNotificationStore((state) => state.fetchNotifications);

    useEffect(() => {
        const fetchData = async () => {
            await fetchUser();
            await fetchNotifications();
        };

        fetchData();
    }, [fetchUser, fetchNotifications]);

    return <Routes />;
}
