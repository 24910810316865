import { useEffect, useState } from 'react';

import { AddCircle } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { ButtonFL } from '../../components/ButtonFL';
import styles from '../../_assets/css/generic.module.css';
import { mensagemVazio, showMessage } from '../../data/Interfaces';
import { Excluir, Get } from '../../data/Verbs';
import { Alert } from '../../components/common/Alert';
import { Loader } from '../../components/Loader';
import { Search } from '../../components/common/Search';
import { TemplatesList } from './TemplatesList';
import { CommonMessage } from '../../components/common/CommonMessage';
import { useError } from '../../contexts/ErrorContext';

export function Templates() {
    const [query, setQuery] = useState(localStorage.getItem('templates-query') || '');
    const [ordenacao, setOrdenacao] = useState('titulo_asc');
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [message, setMessage] = useState(mensagemVazio);
    const [templates, setTemplates] = useState([]);

    const { showError } = useError();

    const ExcluirTemplate = async (template) => {
        setIsLoading(true);

        try {
            await Excluir(`${process.env.REACT_APP_API_URL}/templates/${template.id}`);

            showMessage(
                {
                    variant: 'warning',
                    message: 'Template excluido com sucesso',
                },
                setMessage,
            );

            setReload(!reload);
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleBusca = (_query) => {
        setIsLoading(true);

        setQuery(_query);
    };

    const handleOrdenar = (campo) => {
        setIsLoading(true);

        if (ordenacao === `${campo}_asc`) {
            setOrdenacao(`${campo}_desc`);
        } else {
            setOrdenacao(`${campo}_asc`);
        }
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const search = query ? `query=${query}&` : '';
                const data = await Get(`${process.env.REACT_APP_API_URL}/templates?${search}sort=${ordenacao}`);
                setTemplates(data);
            } catch (error) {
                showError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUsers();
    }, [query, ordenacao, reload]);

    useEffect(() => {
        localStorage.setItem('templates-query', query);
    }, [query]);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className={styles.container}>
                    {message.message && <Alert variant={message.variant} message={message.message} />}
                    <Search
                        query={query}
                        handleAdicionar={
                            <ButtonFL
                                component={Link}
                                to="/templates/novo"
                                startIcon={<AddCircle />}
                                textKey="Adicionar"
                            />
                        }
                        handleBusca={handleBusca}
                    />
                    <CommonMessage
                        message={
                            templates.length === 0
                                ? 'Não temos nenhum template cadastrado'
                                : `Listando ${templates.length} templates para a sua busca: ${query}`
                        }
                    />

                    <TemplatesList
                        handleExcluir={ExcluirTemplate}
                        handleOrdenar={handleOrdenar}
                        templates={templates}
                        ordenacao={ordenacao}
                    />
                </div>
            )}
        </>
    );
}
