import { useEffect, useState } from 'react';
import { Avatar, Box, Grid, TextField, Typography } from '@mui/material';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import { useNavigate } from 'react-router-dom';
import { mensagemVazio, showMessage } from '../../data/Interfaces';
import { Loader } from '../../components/Loader';
import { Alert } from '../../components/common/Alert';

import { ButtonFL } from '../../components/ButtonFL';
import { BackButtonFL } from '../../components/BackButtonFL';
import { apiResetPassword } from '../../services';
import { alertColor, removeSpacesFromString, emailValidator } from '../../utils';

export const ForgotPassword = () => {
    const navigator = useNavigate();
    const [message, setMessage] = useState(mensagemVazio);
    const [isLoading, setIsLoading] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [email, setEmail] = useState('');

    const { message: messageAlert, variant } = message;

    const showMessageAlert = (alertColor, message) => {
        showMessage(
            {
                variant: alertColor,
                message,
            },
            setMessage,
        );
    };

    const onChange = (event) => {
        setEmail(event.target.value);
        validateEmail(event.target.value);
    };

    const validateEmail = (value) => {
        const isEmail = emailValidator(value);
        setButtonDisabled(!isEmail);
    };

    const handleSubmit = async (event) => {
        setIsLoading(true);
        event.preventDefault();

        try {
            const data = new FormData(event.currentTarget);

            const email = removeSpacesFromString(data.get('email'));

            const { message } = await apiResetPassword(email);

            if (message) {
                showMessageAlert(alertColor.success, message);
                setButtonDisabled(true);
            }
        } catch (error) {
            showMessageAlert(alertColor.error, error.message);
            setButtonDisabled(true);
        } finally {
            setEmail('');
            setIsLoading(false);
        }
    };

    const handleGoBack = () => {
        navigator(-1);
    };

    useEffect(() => {
        setIsLoading(false);
    }, []);

    return (
        <>
            <BackButtonFL handleGoBack={handleGoBack} />
            {messageAlert && <Alert variant={variant} message={messageAlert} />}
            {isLoading ? (
                <Loader />
            ) : (
                <Grid
                    container
                    component="div"
                    sx={{
                        height: '85vh',
                        backgroundColor: 'var(--white-color)',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Grid item xs={10} sm={10} md={10} elevation={8}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Avatar
                                sx={{
                                    m: 3,
                                    bgcolor: 'var(--primary-color)',
                                }}
                            >
                                <LockResetOutlinedIcon />
                            </Avatar>
                            <Typography variant="h3" sx={{ color: 'var(--primary-color)' }}>
                                Esqueci minha senha
                            </Typography>

                            <Box component="div" sx={{ marginTop: 3, width: '40vh', textAlign: 'center' }}>
                                <Typography variant="body1" sx={{ color: 'var(--grey-800-color)' }}>
                                    Insira o e-mail vinculado à sua conta, e enviaremos um link para que você possa
                                    redefinir sua senha.
                                </Typography>
                            </Box>

                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="email"
                                    label={'Email'}
                                    type="text"
                                    id="email"
                                    onChange={onChange}
                                    value={email}
                                />
                                <ButtonFL
                                    textKey="Enviar E-mail"
                                    buttonDisabled={buttonDisabled}
                                    type="submit"
                                    fullWidth
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </>
    );
};
