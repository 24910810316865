import { Get } from '../data/Verbs';

export const apiGetUnread = async () => {
    try {
        const response = await Get(`${process.env.REACT_APP_API_URL}/chat/unread`);
        const filteredResponse = response.filter((item) => item.tipoChat != 1 && item.lido == null);
        return filteredResponse;
    } catch (error) {
        throw error;
    }
};
