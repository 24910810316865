import { Box, Toolbar } from '@mui/material';
import React, { useEffect } from 'react';
import { Login } from '../../pages/login';
import { useMobileDetect } from '../../hooks';
import { useAuthStore } from '../../store';
import { DashboardDrawerMenu } from '../../components/DashboardDrawerMenu';
import { NotificationMenu } from '../../components/NotificationMenu';

function DashboardContent({ children, title }) {
    const defaultOpen = false;

    const user = useAuthStore((state) => state.user);
    const [open, setOpen] = React.useState(defaultOpen);
    const isMobile = useMobileDetect();

    const checkIfMobile = () => (isMobile ? setOpen(!isMobile) : setOpen(defaultOpen));

    useEffect(() => {
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);
        return () => {
            window.removeEventListener('resize', checkIfMobile);
        };
    }, []);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <>
            {user ? (
                <Box sx={{ display: 'flex', position: 'relative' }}>
                    <DashboardDrawerMenu toggleDrawer={toggleDrawer} open={open} />
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: 'var(--grey-100-color)',
                            flexGrow: 1,
                            pt: '40px',
                            px: { xs: '0px', md: '10px' },
                            overflow: { xs: 'auto', md: 'unset' },
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 16,
                                right: 30,
                                zIndex: 1000,
                            }}
                        >
                            <NotificationMenu />
                        </Box>
                        <Box sx={{ marginTop: '32px' }}>{children}</Box>
                    </Box>
                </Box>
            ) : (
                <Login />
            )}
        </>
    );
}

export const Dashboard = ({ title, children }) => {
    return <DashboardContent title={title}>{children}</DashboardContent>;
};
