import React, { createContext, useState, useContext } from 'react';
import { ErrorModal } from '../components/error/ErrorModal';
import { logError } from '../components/error/ErrorHandler';

const ErrorContext = createContext();

export const useError = () => {
    return useContext(ErrorContext);
};

export const ErrorProvider = ({ children }) => {
    const [code, setCode] = useState('');
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    const showError = (error) => {
        const { code, message } = error;
        setCode(code);
        logError(code, message);
        setIsErrorModalOpen(true);
    };

    const handleCloseErrorModal = () => {
        setIsErrorModalOpen(false);
    };

    return (
        <ErrorContext.Provider value={{ showError }}>
            {children}
            <ErrorModal open={isErrorModalOpen} handleClose={handleCloseErrorModal} code={code} />
        </ErrorContext.Provider>
    );
};
