import { Patch } from '../data/Verbs';

export const apiUpdateDefaultEscopo = async (id) => {
    try {
        const response = await Patch(`${process.env.REACT_APP_API_URL}/escopos/updatedefault/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
};
