import { Post } from '../data/Verbs';

export const apiPostDuplicateCampanhaItem = async (idsToDuplicate) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/campanhasItens/duplicar`, {
            idsToDuplicate,
        });
        return response;
    } catch (error) {
        throw new Error('Ocorreu um erro ao tentar duplicar.');
    }
};
