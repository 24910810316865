import React, { useEffect, useState } from 'react';

import { FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select, Switch, TextField } from '@mui/material';
import { Delete } from '@mui/icons-material';

export const WorkflowEtapa = ({
    hasCampanhas,
    WorkflowEtapa,
    etapas,
    handleUpdateEtapa,
    handleDeleteWorkflowEtapa,
}) => {
    const [etapaTitulo, setEtapaTitulo] = useState(WorkflowEtapa?.etapaTitulo || '');
    const [etapaSelecionada, setEtapaSelecionada] = useState(WorkflowEtapa?.etapa || '');
    const [aprovacaoInterna, setAprovacaoInterna] = useState(WorkflowEtapa?.aprovacaoInterna || false);
    const [aprovacaoExterna, setAprovacaoExterna] = useState(WorkflowEtapa?.aprovacaoExterna || false);

    useEffect(() => {
        if (!etapaTitulo) setEtapaTitulo(etapaSelecionada);
    }, [etapaSelecionada]);

    useEffect(() => {
        if (etapaSelecionada && etapaTitulo) {
            handleUpdateEtapa({
                id: WorkflowEtapa?.id || null,
                etapa: etapaSelecionada,
                etapaTitulo,
                aprovacaoInterna,
                aprovacaoExterna,
            });
        }
    }, [etapaTitulo, etapaSelecionada, aprovacaoInterna, aprovacaoExterna]);

    return (
        <>
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <Select
                            value={etapaSelecionada}
                            onChange={(e) => setEtapaSelecionada(e.target.value)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="" disabled>
                                Etapa
                            </MenuItem>
                            {etapas &&
                                etapas.map((etapa, index) => (
                                    <MenuItem key={index} value={etapa.id}>
                                        {etapa.id}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField
                            label="Nome da Etapa"
                            value={etapaTitulo}
                            onChange={(e) => setEtapaTitulo(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                    <FormControlLabel
                        control={
                            <Switch
                                disabled={!!hasCampanhas}
                                checked={aprovacaoInterna}
                                onChange={(e) => setAprovacaoInterna(e.target.checked)}
                                sx={{
                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                        color: 'var(--primary-color)',
                                    },
                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                        backgroundColor: 'var(--primary-color)',
                                    },
                                }}
                            />
                        }
                        label="Aprovação Interna"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                disabled={!!hasCampanhas}
                                checked={aprovacaoExterna}
                                onChange={(e) => setAprovacaoExterna(e.target.checked)}
                                sx={{
                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                        color: 'var(--primary-color)',
                                    },
                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                        backgroundColor: 'var(--primary-color)',
                                    },
                                }}
                            />
                        }
                        label="Aprovação Externa"
                    />
                </Grid>
                <Grid
                    item
                    xs={1}
                    style={{
                        padding: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {!hasCampanhas && WorkflowEtapa?.id && (
                        <IconButton color="primary" onClick={() => handleDeleteWorkflowEtapa(WorkflowEtapa.id)}>
                            <Delete style={{ color: 'var(--primary-color)' }} />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        </>
    );
};
