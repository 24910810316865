export const ordenarData = (produtos, campoData, ordem) => {
    const sortedProdutos = [...produtos].sort((produtoA, produtoB) => {
        const dataA = new Date(produtoA[campoData]).getTime();
        const dataB = new Date(produtoB[campoData]).getTime();
        return ordem === 'asc' ? dataA - dataB : dataB - dataA;
    });

    return sortedProdutos;
};

export function sortObject(object, fields) {
    return object.sort((itemA, itemB) => {
        for (let field of fields) {
            if (itemA[field] < itemB[field]) return -1;
            if (itemA[field] > itemB[field]) return 1;
        }
        return 0;
    });
}

export function generateRandomNumber() {
    return Math.floor(100000 + Math.random() * 900000).toString();
}
