import { useState } from 'react';

import { ButtonFL } from '../../components/ButtonFL';

import { Download } from '../../data/Verbs';
import { Loader } from '../../components/Loader';
import { useError } from '../../contexts/ErrorContext';

export const CampanhasToExcel = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { showError } = useError();
    const fetchData = async () => {
        try {
            setIsLoading(true);

            const text = await Download(`${process.env.REACT_APP_API_URL}/campanhas/csv`);
            const csvContent = `\uFEFF${text}`;
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');

            a.style.display = 'none';
            a.href = url;
            a.download = 'campanhas.csv';

            document.body.appendChild(a);

            a.click();

            window.URL.revokeObjectURL(url);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showError(error);
        }
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <ButtonFL sx={{ mt: 1 }} textKey="Exportar Base Excel" onClick={fetchData} fullWidth />
            )}
        </>
    );
};
