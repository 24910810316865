import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, Chip } from '@mui/material';
import { Cached } from '@mui/icons-material';
import { ButtonFL } from '../ButtonFL';
import { Post } from '../../data/Verbs';
import { isLightOrDark } from '../../utils';
import { useError } from '../../contexts/ErrorContext';
import { Loader } from '../Loader';

const VideoInfoCard = React.memo(({ videoInfo, handleFrameClick }) => {
    const colorTitle = 'var(--grey-900-color)';
    const fontWeight = 700;

    const [isLoading, setIsLoading] = useState(false);

    const { showError } = useError();
    const hasVideoSubtitle = videoInfo?.videoSubtitle;
    const isProcessing = videoInfo?.status !== 'COMPLETED';
    const isFAILED = videoInfo?.status === 'FAILED';

    const sortedColors = videoInfo?.resumo?.cores
        ?.slice()
        ?.sort((a, b) => parseFloat(b.porcentagem) - parseFloat(a.porcentagem));

    const renderColorPalette = () => (
        <>
            <Typography variant="body1" sx={{ my: 2, fontWeight, color: colorTitle }}>
                Paleta de cores:
            </Typography>
            <Box display="flex" mb={2}>
                {sortedColors?.map((color, index) => {
                    const widthPercentage = `${parseFloat(color.porcentagem)}%`;
                    return (
                        <Box
                            key={index}
                            sx={{
                                bgcolor: color.codigo_hexa,
                                color:
                                    isLightOrDark(color.codigo_hexa) === 'light'
                                        ? 'var(--grey-700-color)'
                                        : 'var(--white-color)',
                                width: widthPercentage,
                                height: 20,
                                textAlign: 'center',
                                lineHeight: '20px',
                                alignSelf: 'center',
                            }}
                        >
                            {color.porcentagem}
                        </Box>
                    );
                })}
            </Box>
        </>
    );

    const renderColorDetails = () => (
        <>
            <Typography variant="body1" sx={{ my: 2, fontWeight, color: colorTitle }}>
                Detalhes de Utilização das Cores:
            </Typography>
            {sortedColors?.map((color, index) => (
                <Box key={index} sx={{ display: 'flex', mb: 2 }}>
                    <Box
                        sx={{
                            bgcolor: color.codigo_hexa,
                            borderRadius: 1,
                            px: 1,
                            mr: 1,
                            color: isLightOrDark(color.codigo_hexa) === 'dark' ? 'var(--grey-100-color)' : color.cor,
                        }}
                    >
                        <Typography variant="body1">{color.codigo_hexa}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1">{color.elementos}</Typography>
                    </Box>
                </Box>
            ))}
        </>
    );

    const reprocessarVideo = async (idVideo) => {
        try {
            setIsLoading(true);
            await Post(`${process.env.REACT_APP_API_URL}/videos/reprocessar/${idVideo}`);
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const renderVideoSubtitle = () => {
        return <Section title="Legenda do post / vídeo" content={videoInfo?.videoSubtitle} />;
    };

    if (isProcessing) {
        return (
            <>
                {isLoading && <Loader />}

                <Card
                    sx={{
                        boxShadow: 'none',
                        minHeight: '60vh',
                        color: 'var(--grey-800-color)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: hasVideoSubtitle ? 'unset' : 'center',
                        alignItems: hasVideoSubtitle ? 'unset' : 'center',
                        px: 3,
                    }}
                >
                    {hasVideoSubtitle && (
                        <>
                            <Typography variant="h5" sx={{ my: 3, fontWeight, color: colorTitle }}>
                                Informações e metadados do vídeo
                            </Typography>
                            {renderVideoSubtitle()}
                        </>
                    )}
                    <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box sx={{ bgcolor: 'var(--grey-50-color)', borderRadius: 2, p: 4 }}>
                            <Typography variant="body1" sx={{ mb: 1, color: colorTitle, textAlign: 'center' }}>
                                Aguarde, o vídeo está sendo processado. <br />
                                Em breve as informações estarão disponíveis aqui.
                            </Typography>
                            {isFAILED && (
                                <ButtonFL
                                    fullWidth
                                    startIcon={<Cached />}
                                    textKey="Reprocessar Vídeo"
                                    onClick={() => reprocessarVideo(videoInfo?.idVideo)}
                                />
                            )}
                        </Box>
                    </CardContent>
                </Card>
            </>
        );
    }

    return (
        <Card sx={{ boxShadow: 'none', overflowY: 'scroll', height: '60vh', color: 'var(--grey-800-color)' }}>
            <CardContent>
                <Typography variant="h5" sx={{ my: 3, fontWeight, color: colorTitle }}>
                    Informações e metadados do vídeo
                </Typography>

                {hasVideoSubtitle && renderVideoSubtitle()}

                <Section title="Transcrição" content={videoInfo?.transcription} />
                <Section title="Descrição visual" content={videoInfo?.resumo?.descricao} />

                <Typography variant="body1" sx={{ mb: 1, fontWeight, color: colorTitle }}>
                    Amostras:
                </Typography>
                <Box display="flex" gap={1} flexWrap="wrap" mb={2}>
                    {videoInfo?.frames?.map((frame, index) => (
                        <img
                            key={index}
                            src={frame?.frameFileName}
                            alt={`Frame ${index}`}
                            style={{ width: 100, cursor: 'pointer' }}
                            onClick={() => handleFrameClick(frame?.frameFileName)}
                        />
                    ))}
                </Box>

                <SectionWithList title="Ambiente" items={videoInfo?.resumo?.ambiente_configuracao} />
                <SectionWithList title="Objetos Identificados" items={videoInfo?.resumo?.objetos_identificados} />
                <SectionWithList title="Pessoas Identificadas" items={videoInfo?.resumo?.pessoas_identificadas} />
                <Section title="Texto na imagem" content={videoInfo?.resumo?.texto_na_imagem} />
                <SectionWithList title="Ações / Atividades" items={videoInfo?.resumo?.acoes_atividades} />
                <SectionWithList title="Sentimento / Emoção" items={videoInfo?.resumo?.sentimento_emocao} />

                {renderColorPalette()}
                {renderColorDetails()}
            </CardContent>
        </Card>
    );
});

const Section = ({ title, content }) => (
    <Box mb={2}>
        <Typography variant="body1" sx={{ mb: 1, fontWeight: 700, color: 'var(--grey-900-color)' }}>
            {title}:
        </Typography>
        <Typography variant="body1" sx={{ fontStyle: title === 'Transcrição' ? 'italic' : 'normal' }}>
            {content}
        </Typography>
    </Box>
);

const SectionWithList = ({ title, items }) => (
    <Box mb={2}>
        <Typography variant="body1" sx={{ mb: 1, fontWeight: 700, color: 'var(--grey-900-color)' }}>
            {title}:
        </Typography>
        <Box display="flex" flexWrap="wrap" gap={1}>
            {Array.isArray(items) &&
                items.map((item, index) => (
                    <Chip
                        key={index}
                        label={item}
                        sx={{
                            backgroundColor: 'var(--grey-50-color)',
                            color: 'var(--grey-700-color)',
                            fontFamily: 'var(--font-family-regular)',
                        }}
                    />
                ))}
        </Box>
    </Box>
);

export default VideoInfoCard;
