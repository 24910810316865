import { ArrowDownward, ArrowUpward, Delete, ModeEdit } from '@mui/icons-material';
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import CreatorSocialLinks from './CreatorSocialLinks';

export const CreatorsList = ({ creators, handleEditar, handleExcluir, handleOrdenar, ordenacao }) => {
    const editarCreator = (creator) => {
        handleEditar(creator);
    };

    const excluirCreator = (creator) => {
        handleExcluir(creator);
    };

    const isOrdenadoAsc = (campo) => {
        return ordenacao === `${campo}_asc`;
    };

    const isOrdenadoDesc = (campo) => {
        return ordenacao === `${campo}_desc`;
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#424242',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const iconStyle = {
        fontSize: '14px',
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell
                            onClick={() => handleOrdenar('nome')}
                            style={{
                                cursor: 'pointer',
                                fontWeight: isOrdenadoAsc('nome') || isOrdenadoDesc('nome') ? 'bold' : 'normal',
                            }}
                        >
                            Nome {isOrdenadoAsc('nome') && <ArrowUpward style={iconStyle} />}{' '}
                            {isOrdenadoDesc('nome') && <ArrowDownward style={iconStyle} />}
                        </StyledTableCell>
                        <StyledTableCell
                            onClick={() => handleOrdenar('email')}
                            style={{
                                cursor: 'pointer',
                                fontWeight: isOrdenadoAsc('email') || isOrdenadoDesc('email') ? 'bold' : 'normal',
                            }}
                        >
                            E-mail {isOrdenadoAsc('email') && <ArrowUpward style={iconStyle} />}{' '}
                            {isOrdenadoDesc('email') && <ArrowDownward style={iconStyle} />}
                        </StyledTableCell>
                        <StyledTableCell
                            onClick={() => handleOrdenar('telefone')}
                            style={{
                                cursor: 'pointer',
                                fontWeight: isOrdenadoAsc('telefone') || isOrdenadoDesc('telefone') ? 'bold' : 'normal',
                            }}
                        >
                            Telefone {isOrdenadoAsc('telefone') && <ArrowUpward style={iconStyle} />}{' '}
                            {isOrdenadoDesc('telefone') && <ArrowDownward style={iconStyle} />}
                        </StyledTableCell>
                        <StyledTableCell>Redes</StyledTableCell>
                        <StyledTableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {creators.map((creator) => (
                        <TableRow
                            key={creator.id}
                            className="nowrap"
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {creator.nome}
                            </TableCell>
                            <TableCell>{creator.email}</TableCell>
                            <TableCell>{creator.telefone}</TableCell>
                            <CreatorSocialLinks creator={creator} />
                            <TableCell align="right">
                                <IconButton
                                    aria-label="Editar Creator"
                                    size="small"
                                    onClick={() => editarCreator(creator)}
                                >
                                    <ModeEdit />
                                </IconButton>

                                <IconButton aria-label="delete" size="small" onClick={() => excluirCreator(creator)}>
                                    <Delete fontSize="small" />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
