import React from 'react';
import { Box, Typography } from '@mui/material';

export const TitleFL = ({
    color,
    title,
    titleSize = 'h5',
    iconTitle,
    subtitle,
    subtitleSize = 'body2',
    iconSubtitle,
}) => {
    const styles = { display: 'flex', alignItems: 'center' };
    return (
        <Box
            sx={{
                color,
            }}
        >
            <Box sx={styles}>
                <Box sx={{ marginRight: iconTitle ? 0.5 : 0 }}>{iconTitle && iconTitle}</Box>
                <Typography variant={titleSize} letterSpacing={1}>
                    {title}
                </Typography>
            </Box>
            <Box sx={styles}>
                <Box>{iconSubtitle && iconSubtitle}</Box>
                {subtitle && <Typography variant={subtitleSize}>{subtitle}</Typography>}
            </Box>
        </Box>
    );
};
