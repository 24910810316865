import { Box, Button } from '@mui/material';
import { escopoVazio } from '../../../data/Interfaces';
import { Escopo } from './Escopo';
import { ButtonFL } from '../../../components/ButtonFL';

export const Escopos = ({ template, creators, handleSalvarEscopo }) => {
    const handleAddEscopo = () => {
        if (!template.escopos) {
            template.escopos = [];
        }

        template.escopos.push(escopoVazio);

        handleSalvarEscopo(template);
    };

    const handleDeleteEscopo = (_escopo) => {
        if (template) {
            template.escopos = template.escopos.filter((escopo) => escopo.id !== _escopo.id);

            handleSalvarEscopo(template);
        }
    };

    const handleSaveEscopo = async (handledEscopo) => {
        if (template) {
            const index = template.escopos.findIndex((escopo) => escopo.id === handledEscopo.id);

            if (index !== -1) {
                template.escopos[index] = handledEscopo;

                await handleSalvarEscopo(template);
            }
        }
    };

    return (
        <>
            <Box sx={{ boxSizing: 'border-box', width: '100%', p: 4 }}>
                <ButtonFL onClick={handleAddEscopo} fullWidth textKey="Inserir Novo Escopo" sx={{ mt: 3, mb: 2 }} />

                <div id="escopos">
                    {template.escopos?.map((escopo, index) => {
                        return (
                            <Escopo
                                key={index}
                                template={template}
                                creators={creators}
                                escopo={escopo}
                                handleSaveEscopo={handleSaveEscopo}
                                handleDeleteEscopo={handleDeleteEscopo}
                            />
                        );
                    })}
                </div>
            </Box>
        </>
    );
};
