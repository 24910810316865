import React from 'react';
import { ChevronLeft, Logout, Menu } from '@mui/icons-material';
import { Divider, IconButton, Drawer as MuiDrawer, Toolbar, Typography, styled, Button, Box } from '@mui/material';
import logo from '../../_assets/logo.png';
import ListItems from '../../components/ListItems';
import { AvatarFL } from '../../components/AvatarFL';
import { useAuthStore } from '../../store';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: open ? drawerWidth : theme.spacing(7),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        overflowX: 'hidden',
        backgroundColor: 'var(--primary-dark-color)',
        color: 'var(--white-color)',
        [theme.breakpoints.up('sm')]: {
            width: open ? drawerWidth : theme.spacing(9),
        },
        display: 'flex',
        flexDirection: 'column',
    },
    '& .MuiSvgIcon-root': {
        fill: 'var(--white-color)',
    },
}));

const UserSection = ({ user, logout, open }) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: open ? 'flex-end' : 'center',
            flexGrow: 1,
            mb: open ? 6 : 0,
            pb: 2,
            height: '20%',
        }}
    >
        <AvatarFL width="50px" height="50px" name={user.nome} />
        <Box
            sx={{
                mt: 4,
                flexDirection: open ? 'row' : 'column',
            }}
        >
            <Button edge="end" color="inherit" aria-label="logout user" onClick={logout}>
                <Logout />
            </Button>
        </Box>
    </Box>
);

export const DashboardDrawerMenu = ({ open, toggleDrawer }) => {
    const user = useAuthStore((state) => state.user);
    const logout = useAuthStore((state) => state.logout);

    return (
        <Drawer variant="permanent" open={open}>
            <Box sx={{ height: '100vh' }}>
                <Toolbar
                    sx={{
                        pr: '24px',
                        bgcolor: 'var(--primary-dark-color)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: open ? 'flex-start' : 'center',
                    }}
                >
                    <IconButton onClick={toggleDrawer}>{open ? <ChevronLeft /> : <Menu />}</IconButton>
                </Toolbar>
                {open && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                        <img src={logo} alt="logo" style={{ maxWidth: '100%' }} />
                    </Box>
                )}
                <ListItems open={open} />
                <Divider sx={{ backgroundColor: 'var(--primary-color)' }} />
                <UserSection user={user} logout={logout} open={open} />
            </Box>
        </Drawer>
    );
};
