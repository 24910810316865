import { Route, Routes as RouterRoutes, useLocation, Navigate } from 'react-router-dom';
import React from 'react';
import { Dashboard } from '../pages/dashboard';
import { Campanha } from '../pages/campanhas/Campanha';
import { Campanhas } from '../pages/campanhas/Campanhas';
import { Clientes } from '../pages/clientes/Clientes';
import { CreateNewPassword } from '../pages/createNewPassword';
import { Creators } from '../pages/creators/Creators';
import { ForgotPassword } from '../pages/forgotPassword';
import { Projetos } from '../pages/projetos/Projetos';
import { Usuarios } from '../pages/usuarios/Usuarios';
import { Escopos } from '../pages/escopos/Escopos';
import { Templates } from '../pages/templates/Templates';
import { Template } from '../pages/templates/Template';
import { Workflows } from '../pages/workflows/Workflows';
import { Workflow } from '../pages/workflows/Workflow';

export const Routes = () => {
    const { pathname: URL } = useLocation();
    const token = URL.substring(URL.lastIndexOf('/') + 1);

    return (
        <>
            <RouterRoutes>
                <Route
                    path="/"
                    element={
                        <Dashboard title="Gestão de Campanhas">
                            <Campanhas />
                        </Dashboard>
                    }
                />
                <Route
                    path="/campanhas"
                    element={
                        <Dashboard title="Gestão de Campanhas">
                            <Campanhas />
                        </Dashboard>
                    }
                />
                <Route
                    path="/campanhas/novo"
                    element={
                        <Dashboard title="Gestão de Campanhas">
                            <Campanha />
                        </Dashboard>
                    }
                />
                <Route
                    path="/campanhas/editar/:id"
                    element={
                        <Dashboard title="Gestão de Campanhas">
                            <Campanha />
                        </Dashboard>
                    }
                />
                <Route
                    path="/clientes"
                    element={
                        <Dashboard title="Cadastro de Clientes">
                            <Clientes />
                        </Dashboard>
                    }
                />
                <Route
                    path="/creators"
                    element={
                        <Dashboard title="Cadastro de Creators">
                            <Creators />
                        </Dashboard>
                    }
                />
                <Route
                    path="/escopos"
                    element={
                        <Dashboard title="Modelos de Escopo">
                            <Escopos />
                        </Dashboard>
                    }
                />
                <Route
                    path="/projetos"
                    element={
                        <Dashboard title="Cadastro de Projetos">
                            <Projetos />
                        </Dashboard>
                    }
                />
                <Route
                    path="/usuarios"
                    element={
                        <Dashboard title="Cadastro de Usuários">
                            <Usuarios />
                        </Dashboard>
                    }
                />
                <Route
                    path="/templates"
                    element={
                        <Dashboard title="Modelos de Escopo">
                            <Templates />
                        </Dashboard>
                    }
                />
                <Route
                    path="/templates/novo"
                    element={
                        <Dashboard title="Modelos de Escopo">
                            <Template />
                        </Dashboard>
                    }
                />
                <Route
                    path="/templates/editar/:id"
                    element={
                        <Dashboard title="Modelos de Escopo">
                            <Template />
                        </Dashboard>
                    }
                />
                <Route
                    path="/workflows"
                    element={
                        <Dashboard title="Modelos de Workflow">
                            <Workflows />
                        </Dashboard>
                    }
                />
                <Route
                    path="/workflows/novo"
                    element={
                        <Dashboard title="Modelos de Workflow">
                            <Workflow />
                        </Dashboard>
                    }
                />
                <Route
                    path="/workflows/editar/:id"
                    element={
                        <Dashboard title="Modelos de Workflow">
                            <Workflow />
                        </Dashboard>
                    }
                />
                <Route path="/forgotPassword" element={<ForgotPassword />} />
                <Route path="/createNewPassword" element={<CreateNewPassword />} />
                <Route path={`createNewPassword/${token}`} element={<CreateNewPassword />} />
            </RouterRoutes>
        </>
    );
};
