import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import './_assets/css/global.css';
import { ErrorProvider } from './contexts/ErrorContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ErrorProvider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ErrorProvider>,
);
