import { FormControl, InputLabel, OutlinedInput, Box } from '@mui/material';
import { useState } from 'react';
import { Cancel } from '@mui/icons-material';
import { GenericModal } from '../../components/common/GenericModal';
import { ButtonFL } from '../../components/ButtonFL';

export const CampanhaItensReprova = ({ item, onClose, handleOnReproveCampanhaItem }) => {
    const [reprovedMessage, setReprovedMessage] = useState('');
    const handleReprova = () => {
        handleOnReproveCampanhaItem(item?.id, reprovedMessage);
    };

    return (
        <GenericModal open={true} onClose={() => onClose()}>
            <Box sx={{ padding: 3 }}>
                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="descricao">Descrição da reprovação*</InputLabel>
                    <OutlinedInput
                        multiline
                        minRows={3}
                        name="reprovacao"
                        id="reprovacao"
                        value={reprovedMessage}
                        onChange={(e) => setReprovedMessage(e.target.value)}
                        label="Descrição da reprovação"
                        required
                    />
                </FormControl>
                <ButtonFL
                    startIcon={<Cancel />}
                    bgcolor="var(--error-color)"
                    bgColorHover="var(--error-light-color)"
                    textKey="Reprovar"
                    fullWidth
                    onClick={handleReprova}
                />
            </Box>
        </GenericModal>
    );
};
