import { Post } from '../data/Verbs';

export const apiPostCampanhasItens = async (campanhaItens) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/campanhasitens/multiple`, {
            campanhaItens,
        });

        return response;
    } catch (error) {
        throw error;
    }
};
