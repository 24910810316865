import { Instagram, Twitter, YouTube } from '@mui/icons-material';
import TableCell from '@mui/material/TableCell';

import { TikTokIcon } from '../../_assets/icons/TikTokIcon';

const CreatorSocialLinks = ({ creator }) => {
    const iconSize = '24px';

    return (
        <TableCell>
            {creator.instagram && (
                <a href={creator.instagram} target="_blank" rel="noopener noreferrer" style={{ color: '#E4405F' }}>
                    <Instagram style={{ fontSize: iconSize }} />
                </a>
            )}
            {creator.tiktok && (
                <a href={creator.tiktok} target="_blank" rel="noopener noreferrer" style={{ color: '#000' }}>
                    <TikTokIcon size="24px" />
                </a>
            )}
            {creator.youtube && (
                <a href={creator.youtube} target="_blank" rel="noopener noreferrer" style={{ color: '#FF0000' }}>
                    <YouTube style={{ fontSize: iconSize }} />
                </a>
            )}
            {(creator.twitch || creator.twitter) && (
                <a
                    href={creator.twitch || creator.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#9146FF' }}
                >
                    <Twitter style={{ fontSize: iconSize }} />
                </a>
            )}
        </TableCell>
    );
};

export default CreatorSocialLinks;
