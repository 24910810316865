import { Post } from '../data/Verbs';

export const apiResetPassword = async (email) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/usuarios/resetPassword`, { email: 'lala@' });
        return response;
    } catch (error) {
        throw error;
    }
};
