export * from './getStatusColor.utils';
export { capitalizerFirstLetter } from './/captalizerFirstLetter.utils';
export { cutMessage } from './cutMessage.utils.js';
export { alertColor } from './alertColors.utils';
export { removeSpacesFromString } from './removeSpacesFromString.utils';
export { emailValidator } from './emailValidator.utils';
export { valorToDatabase } from './valorToDatabase';
export { getFirstLetterString } from './getFirstLetterString.utils';
export { getIconByCategory } from './getIconByCategory.utils.js';
export { generateLink } from './generateLink.utils.js';
export { getStageCampain } from './getStageCampain.utils.js';
export { formatDate } from './formatDate.utils.js';
export { identifyFileType } from './identifyFileType.utils.js';
export { isLightOrDark } from './isLightOrDark.utils.js';
export * from './formsDefaultValues.js';

export const placeholder = 'Vazio';

export const Waiting = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};
