import { Delete as DeleteIcon, ModeEdit as ModeEditIcon } from '@mui/icons-material';
import { IconButton, Paper, Table, TableBody, TableContainer, TableHead, TableRow, styled } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import { accessLevels } from '../../data/Interfaces';
import { useAuthStore } from '../../store';

export const UsuariosLista = ({ usuarios, handleEditar, handleExcluir }) => {
    const user = useAuthStore((state) => state.user);

    const editarUsuario = (usuario) => {
        handleEditar(usuario);
    };

    const excluirUsuario = (usuario) => {
        handleExcluir(usuario);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#424242',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Nome</StyledTableCell>
                        <StyledTableCell>E-mail</StyledTableCell>
                        <StyledTableCell>Telefone</StyledTableCell>
                        <StyledTableCell>Acesso</StyledTableCell>
                        {user.acesso === 1 && <StyledTableCell />}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {usuarios.map((usuario) => (
                        <TableRow
                            key={usuario.id}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {usuario.nome}
                            </TableCell>
                            <TableCell>{usuario.email}</TableCell>
                            <TableCell>{usuario.telefone}</TableCell>
                            <TableCell>{usuario.acesso && accessLevels[usuario.acesso - 1].label}</TableCell>

                            {user.acesso === 1 && (
                                <TableCell align="right">
                                    <IconButton
                                        aria-label="Editar Usuário"
                                        size="small"
                                        onClick={() => editarUsuario(usuario)}
                                    >
                                        <ModeEditIcon />
                                    </IconButton>

                                    <IconButton
                                        aria-label="delete"
                                        size="small"
                                        onClick={() => excluirUsuario(usuario)}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
