import { Autocomplete, TextField } from '@mui/material';

export const Creators = ({ creators, creator, creatorsFiltered, setCreator, creatorIdentify = 0 }) => {
    const creatorsList = creatorsFiltered ? creatorsFiltered : creators;

    return (
        <>
            <Autocomplete
                id="creators"
                variant="filled"
                options={creatorsList}
                getOptionLabel={(option) => (option.email ? `${option.nome} - ${option.email}` : option.nome)}
                value={(creators && creators?.find((option) => option.id === creator)) || null}
                onChange={(event, newValue) => {
                    if (creatorIdentify === 0) setCreator(newValue ? newValue.id : null);
                    else setCreator(newValue ? newValue.id : null, creatorIdentify);
                }}
                renderInput={(params) => (
                    <TextField {...params} label="Creators" variant="filled" size="small" fullWidth />
                )}
            />
        </>
    );
};
