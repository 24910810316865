import React, { useEffect, useRef, useState } from 'react';
import { Box, CardHeader, Divider } from '@mui/material';
import { ChatsFormulario } from './ChatsFormulario';
import ChatsMensagens from './ChatsMensagens';
import { apiPostChat } from '../../services/apiPostChat';
import { Get, Post } from '../../data/Verbs';
import { TitleFL } from '../TitleFL';
import { Loader } from '../Loader';
import { AvatarFL } from '../../components/AvatarFL';
import { useAuthStore, useNotificationStore } from '../../store';
import { apiGetUsuariosCliente } from '../../services/apiGetUsuariosCliente';
import { apiGetCampanha } from '../../services/apiGetCampanha';
import { useError } from '../../contexts/ErrorContext';

export const Chats = ({ item, contexto, title, iconTitle, hasHeader, headerTitle, creator }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [updated, setUpdated] = useState(false);
    const [chats, setChats] = useState([]);
    const user = useAuthStore((state) => state.user);

    const fetchNotifications = useNotificationStore((state) => state.fetchNotifications);

    const messagesContainerRef = useRef(null);

    const { showError } = useError();

    const handleSaveChat = async (mensagem) => {
        setIsLoading(true);

        const chat = {
            mensagem: mensagem,
            idusuario: user?.id,
            contexto,
        };

        if (contexto == 'ADMIN_CREATOR_CAMPANHAITEM' || contexto == 'ADMIN_CLIENTE_CAMPANHAITEM') {
            chat.idcampanhaitem = item?.id;

            if (contexto == 'ADMIN_CREATOR_CAMPANHAITEM') chat.idcreator = Number(item?.creator);
            if (contexto == 'ADMIN_CLIENTE_CAMPANHAITEM') chat.idcliente = Number(item?.cliente);
        }

        if (contexto == 'ADMIN_CREATOR_CAMPANHA' || contexto == 'ADMIN_CLIENTE_CAMPANHA') {
            chat.idcampanha = item?.id;

            if (contexto == 'ADMIN_CREATOR_CAMPANHA') chat.idcreator = Number(item?.creator);
            if (contexto == 'ADMIN_CLIENTE_CAMPANHA') chat.idcliente = Number(item?.cliente);
        }

        if (contexto == 'ADMIN_CLIENTE_CAMPANHA' || contexto == 'ADMIN_CLIENTE_CAMPANHAITEM') {
            let idcliente = item?.cliente;
            let idcampanha = contexto == 'ADMIN_CLIENTE_CAMPANHA' ? item.id : item.campanha;

            if (!idcliente || idcliente == undefined) {
                const campanhaData = await apiGetCampanha(idcampanha);
                idcliente = campanhaData?.cliente;
            }

            if (idcliente) {
                const clientes = await apiGetUsuariosCliente(idcliente);
                chat.idclienteusuario = clientes[0].id;
            }
        }

        await apiPostChat(chat);

        setUpdated((prevUpdated) => !prevUpdated);
    };

    const handleGetRead = async (chatsToRead) => {
        try {
            if (chatsToRead && chatsToRead.length > 0) {
                await Post(`${process.env.REACT_APP_API_URL}/chat/lido/usuarios`, {
                    chats: chatsToRead,
                });

                fetchNotifications();
                setUpdated((prevUpdated) => !prevUpdated);
            }
        } catch (error) {
            showError(error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (item?.id) {
                    let filter = {};

                    if (contexto == 'ADMIN_CREATOR_CAMPANHAITEM' || contexto == 'ADMIN_CLIENTE_CAMPANHAITEM') {
                        filter.idcampanhaitem = item?.id;
                    }

                    if (contexto == 'ADMIN_CREATOR_CAMPANHA' || contexto == 'ADMIN_CLIENTE_CAMPANHA') {
                        filter.idcampanha = item?.id;
                    }

                    if (creator) filter.idcreator = creator;

                    filter.contexto = contexto;

                    const queryString = Object.entries(filter)
                        .map(([key, value]) => `${key}=${value}`)
                        .join('&');

                    const dataChats = await Get(
                        `${process.env.REACT_APP_API_URL}/chat/?${queryString}&sort=created_at_asc`,
                    );

                    const dataChatsUnread = dataChats.filter((chat) => !chat.lido);
                    await handleGetRead(dataChatsUnread);

                    setChats(dataChats);
                }
            } catch (error) {
                showError(error);
            }
        };

        fetchData();
    }, [item, updated]);

    useEffect(() => {
        const scrollToBottom = async () => {
            if (messagesContainerRef.current) {
                messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
            }
        };

        scrollToBottom();
        setIsLoading(false);
    }, [chats]);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box sx={{ bgcolor: 'var(--white-color)', borderRadius: 2, p: 2 }}>
                    {hasHeader && (
                        <Box sx={{ marginBottom: 4 }}>
                            <CardHeader
                                avatar={<AvatarFL name={headerTitle} width={30} height={30} fontSize={12} />}
                                title={headerTitle}
                                titleTypographyProps={{ fontSize: 16 }}
                            />
                            <Divider />
                        </Box>
                    )}

                    {title && (
                        <Box sx={{ marginBottom: 2 }}>
                            <TitleFL titleSize="body1" title={title} iconTitle={iconTitle} />
                        </Box>
                    )}

                    <Box ref={messagesContainerRef} sx={{ maxHeight: '30vh', overflow: 'auto' }}>
                        <ChatsMensagens key={contexto} chats={chats} />
                    </Box>
                    <Box>
                        <ChatsFormulario onSave={handleSaveChat} />
                    </Box>
                </Box>
            )}
        </>
    );
};
