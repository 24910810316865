import { useEffect, useState } from 'react';

import { AddCircle } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { ButtonFL } from '../../components/ButtonFL';

import styles from '../../_assets/css/generic.module.css';
import { mensagemVazio, showMessage } from '../../data/Interfaces';
import { Excluir, Get } from '../../data/Verbs';
import { Alert } from '../../components/common/Alert';
import { Loader } from '../../components/Loader';
import { Search } from '../../components/common/Search';
import { WorkflowsList } from './WorkflowsList';
import { CommonMessage } from '../../components/common/CommonMessage';
import { useError } from '../../contexts/ErrorContext';

export function Workflows() {
    const [query, setQuery] = useState(localStorage.getItem('workflows-query') || '');
    const [ordenacao, setOrdenacao] = useState('nome_asc');
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [message, setMessage] = useState(mensagemVazio);
    const [workflows, setWorkflows] = useState([]);

    const { showError } = useError();

    const ExcluirWorkflow = async (workflow) => {
        setIsLoading(true);

        try {
            await Excluir(`${process.env.REACT_APP_API_URL}/workflows/${workflow.id}`);

            showMessage(
                {
                    variant: 'success',
                    message: 'Workflow excluido com sucesso',
                },
                setMessage,
            );

            setReload(!reload);
        } catch (error) {
            setIsLoading(false);
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleBusca = (_query) => {
        setIsLoading(true);
        setQuery(_query);
    };

    const handleOrdenar = (campo) => {
        setIsLoading(true);

        if (ordenacao === `${campo}_asc`) {
            setOrdenacao(`${campo}_desc`);
        } else {
            setOrdenacao(`${campo}_asc`);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data =
                    query === ''
                        ? []
                        : await Get(`${process.env.REACT_APP_API_URL}/workflows?query=${query}&sort=${ordenacao}`);

                setWorkflows(data);
            } catch (error) {
                showError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [query, ordenacao, reload]);

    useEffect(() => {
        localStorage.setItem('workflows-query', query);
    }, [query]);

    return (
        <>
            {isLoading && <Loader />}

            <div className={styles.container}>
                {message.message && <Alert variant={message.variant} message={message.message} />}

                <Search
                    query={query}
                    handleAdicionar={
                        <ButtonFL
                            component={Link}
                            to="/workflows/novo"
                            startIcon={<AddCircle />}
                            sx={{ mt: 1, mb: 1 }}
                            textKey="Adicionar"
                        />
                    }
                    handleBusca={handleBusca}
                />

                <CommonMessage
                    message={
                        query === ''
                            ? 'Para pesquisar workflows, utilize a busca acima'
                            : `Listando ${workflows.length} workflows para a sua busca: ${query}`
                    }
                />

                {query && (
                    <>
                        <WorkflowsList
                            handleExcluir={ExcluirWorkflow}
                            handleOrdenar={handleOrdenar}
                            workflows={workflows}
                            ordenacao={ordenacao}
                        />
                    </>
                )}
            </div>
        </>
    );
}
