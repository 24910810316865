import { useEffect, useState } from 'react';

import {
    Avatar,
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from '@mui/material';

import { FolderCopy } from '@mui/icons-material';
import { ButtonFL } from '../../components/ButtonFL';

import { Get } from '../../data/Verbs';

import { useError } from '../../contexts/ErrorContext';

export const Projeto = ({ projeto, handleFechar, handleSalvar }) => {
    const [clientes, setClientes] = useState([]);
    const [workflows, setWorkflows] = useState([]);
    const [form, setForm] = useState({
        titulo: projeto.titulo || '',
        cliente: projeto.cliente || '',
        workflow: projeto.workflow || '',
        descricao: projeto.descricao || '',
    });

    const { showError } = useError();

    const handleSubmit = (event) => {
        event.preventDefault();

        const updated = {
            ...projeto,
            titulo: form.titulo,
            cliente: form.cliente,
            workflow: form.workflow,
            descricao: form.descricao,
            clienteNome: clientes.find((cliente) => cliente.id === form.cliente)?.nome || '',
        };

        handleSalvar(updated);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Get(`${process.env.REACT_APP_API_URL}/clientes?sort=nome_asc`);
                const dataWorkFlows = await Get(`${process.env.REACT_APP_API_URL}/workflows`);
                setClientes(data);
                setWorkflows(dataWorkFlows);
            } catch (error) {
                showError(error);
            }
        };

        fetchData();
    }, []);

    return (
        <Box
            sx={{
                margin: 2,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'var(--white-color)',
            }}
        >
            <Avatar
                sx={{
                    m: 1,
                    mt: 3,
                    bgcolor: 'var(--primary-color)',
                }}
            >
                <FolderCopy />
            </Avatar>

            <Typography component="h1" variant="h5">
                {projeto.id === '0' ? 'Adicionar Projeto' : 'Alterar Projeto'}
            </Typography>

            <Box component="form" onSubmit={handleSubmit} sx={{ boxSizing: 'border-box', width: '100%', p: 4 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="titulo"
                    label="Título"
                    id="titulo"
                    value={form.titulo}
                    onChange={handleInputChange}
                    autoComplete="Título"
                />

                {clientes.length > 0 ? (
                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel htmlFor="cliente">Cliente</InputLabel>
                        <Select
                            name="cliente"
                            id="cliente"
                            label="Cliente"
                            value={form.cliente}
                            onChange={handleInputChange}
                            required
                        >
                            {clientes.map((cliente) => (
                                <MenuItem key={cliente.id} value={cliente.id}>
                                    {cliente.nome}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    <div>Carregando clientes...</div>
                )}

                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="workflow">Workflow</InputLabel>
                    <Select
                        name="workflow"
                        id="workflow"
                        label="Workflow"
                        value={form.workflow}
                        onChange={handleInputChange}
                        required
                    >
                        {workflows.map((workflow, index) => (
                            <MenuItem key={index} value={workflow.id}>
                                {workflow.nome}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="descricao">Descrição</InputLabel>
                    <OutlinedInput
                        multiline
                        minRows={3}
                        name="descricao"
                        id="descricao"
                        value={form.descricao}
                        onChange={handleInputChange}
                        label="Descrição"
                    />
                </FormControl>

                <ButtonFL type="submit" fullWidth textKey="Salvar" />
                <ButtonFL textKey="Voltar" fullWidth onClick={() => handleFechar(false)} />
            </Box>
        </Box>
    );
};
