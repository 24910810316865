import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import { mensagemVazio, showMessage } from '../../data/Interfaces';
import { Get, Patch, Post } from '../../data/Verbs';
import { Alert } from '../../components/common/Alert';
import { Escopos } from './escopos/Escopos';
import { Loader } from '../../components/Loader';
import { TemplateDados } from './TemplateDados';
import { useError } from '../../contexts/ErrorContext';

export const Template = () => {
    const { id } = useParams();

    const [template, setTemplate] = useState({});
    const [creators, setCreators] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [updated, setUpdated] = useState(false);
    const [message, setMessage] = useState(mensagemVazio);

    const navigate = useNavigate();

    const { showError } = useError();

    const handleSubmit = async (handleTemplate) => {
        try {
            if (handleTemplate.id) {
                try {
                    setIsLoading(true);
                    await Patch(`${process.env.REACT_APP_API_URL}/templates/${handleTemplate.id}`, handleTemplate);
                    showMessage({ variant: 'success', message: 'Template alterado com sucesso!' }, setMessage);
                } catch (error) {
                    showError(error);
                } finally {
                    setIsLoading(false);
                }
            } else {
                try {
                    const response = await Post(`${process.env.REACT_APP_API_URL}/templates`, handleTemplate);
                    showMessage({ variant: 'success', message: 'Template inserido com sucesso!' }, setMessage);
                    setIsLoading(false);
                    navigate(`/templates/editar/${response.id}`);
                } catch (error) {
                    setIsLoading(false);
                    showError(error);
                }
            }

            setUpdated((prevUpdated) => !prevUpdated);
        } catch (error) {
            showMessage({ variant: 'error', message: 'Ops! Ocorreu um erro ao salvar o título' }, setMessage);
        }
    };

    const handleSalvarEscopo = async (handleTemplate) => {
        try {
            setIsLoading(true);
            await Patch(`${process.env.REACT_APP_API_URL}/templates/${handleTemplate.id}`, handleTemplate);

            setUpdated((prevUpdated) => !prevUpdated);
        } catch (error) {
            showMessage({ variant: 'warning', message: error.message }, setMessage);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                try {
                    const data = await Get(`${process.env.REACT_APP_API_URL}/templates/${id}`);

                    if (data && data[0]) {
                        setTemplate(data[0]);
                    }
                } catch (error) {
                    showError(error);
                }
            }

            setIsLoading(false);
        };

        fetchData();
    }, [id, updated]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dataCreators = await Get(`${process.env.REACT_APP_API_URL}/creators?sort=nome_asc`);
                setCreators(dataCreators);
            } catch (error) {
                showError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Box
                        sx={{
                            margin: 2,
                            borderRadius: 2,
                            backgroundColor: 'var(--white-color)',
                        }}
                    >
                        {message.message && <Alert variant={message.variant} message={message.message} />}
                    </Box>
                    <Box
                        sx={{
                            margin: 2,
                            borderRadius: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'var(--white-color)',
                        }}
                    >
                        <TemplateDados _handleSubmit={handleSubmit} template={template} setIsLoading={setIsLoading} />
                        {template.id && (
                            <>
                                <Escopos
                                    template={template}
                                    creators={creators}
                                    handleSalvarEscopo={handleSalvarEscopo}
                                    setIsLoading={setIsLoading}
                                />
                            </>
                        )}
                    </Box>
                </>
            )}
        </>
    );
};
