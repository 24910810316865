import { useEffect, useState } from 'react';

import { Search } from '@mui/icons-material';
import { Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Get } from '../../data/Verbs';
import { useError } from '../../contexts/ErrorContext';

export const UsuariosSearch = ({ handleAdicionar, handleBusca, query, cliente }) => {
    const [busca, setBusca] = useState(query);
    const [clientes, setClientes] = useState([]);
    const { showError } = useError();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Get(`${process.env.REACT_APP_API_URL}/clientes?sort=nome_asc`);

                setClientes(data);
            } catch (error) {
                showError(error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Grid container spacing={2} sx={{ marginBottom: '5px' }}>
                <Grid item xs={12} sm={12}>
                    {handleAdicionar}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel htmlFor="usuario">Usuário</InputLabel>
                    <TextField
                        required
                        fullWidth
                        id="usuario"
                        name="usuario"
                        autoComplete="Usuario"
                        defaultValue={query}
                        onChange={(e) => setBusca(e.target.value)}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                handleBusca(busca, cliente);
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Buscar Usuário"
                                        size="small"
                                        onClick={() => handleBusca(busca, cliente)}
                                    >
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                {clientes.length > 0 ? (
                    <Grid item xs={12} sm={6}>
                        <InputLabel htmlFor="cliente">Cliente</InputLabel>
                        <Select
                            name="cliente"
                            id="cliente"
                            defaultValue={cliente || ''}
                            onChange={(e) => handleBusca(busca, e.target.value)}
                            required
                            fullWidth
                        >
                            <MenuItem value="">Todos os Clientes</MenuItem>
                            {clientes.map((cliente) => (
                                <MenuItem key={cliente.id} value={cliente.id}>
                                    {cliente.nome}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                ) : (
                    <div>Carregando clientes...</div>
                )}
            </Grid>
        </>
    );
};
