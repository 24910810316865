import React from 'react';
import { Accordion, Box, AccordionSummary, AccordionDetails, Typography, IconButton } from '@mui/material';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TitleFL } from '../TitleFL';
import './accordion.css';

export const AccordionFL = ({
    title,
    description,
    icon,
    color = 'var(--primary-dark-color)',
    handleOpenEditBriefingModal,
    children,
}) => {
    return (
        <Accordion className="accordionFL" sx={{ bgcolor: 'var(--white-color)', color: color }} disableGutters>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                disableGutters
            >
                <TitleFL title={title} iconTitle={icon} />
            </AccordionSummary>
            {!children ? (
                <AccordionDetails>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                            {description}
                        </Typography>
                    </Box>
                </AccordionDetails>
            ) : (
                children
            )}
        </Accordion>
    );
};
