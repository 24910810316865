import React from 'react';
import { CardMedia, Card } from '@mui/material';

export const ImageViewer = ({ imageUrl }) => {
    return (
        <>
            <Card style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CardMedia
                    component="img"
                    image={imageUrl}
                    style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
                />
            </Card>
        </>
    );
};
