import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, Box, InputLabel, TextField, Typography } from '@mui/material';
import { AccountTree } from '@mui/icons-material';
import { mensagemVazio, showMessage } from '../../data/Interfaces';
import { Get, Patch, Post } from '../../data/Verbs';
import { Alert } from '../../components/common/Alert';
import { Loader } from '../../components/Loader';
import { WorkflowEtapa } from './WorkflowEtapas';
import { apiDeleteWorkflowEtapa, apiGetEtapas, apiGetCampanhas } from '../../services';
import { ButtonFL } from '../../components/ButtonFL';
import { useError } from '../../contexts/ErrorContext';

export const Workflow = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { showError } = useError();

    const [workflow, setWorkflow] = useState({});
    const [etapas, setEtapas] = useState([]);
    const [etapasView, setEtapasView] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [updated, setUpdated] = useState(false);
    const [message, setMessage] = useState(mensagemVazio);
    const [form, setForm] = useState({
        nome: '',
        planilha: '',
        planilhaCampanhaItem: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!form.nome) return;

        const filteredEtapas = etapasView.filter(
            (etapa) => etapa && etapa.etapa != null && etapa.etapa != undefined && etapa.etapa.trim() != '',
        );

        const handleWorkflow = {
            ...workflow,
            nome: form.nome,
            planilha: form.planilha,
            planilhaCampanhaItem: form.planilhaCampanhaItem,
            etapas: filteredEtapas,
        };

        setIsLoading(true);
        try {
            if (handleWorkflow.hasCampanhas) {
                const { etapas, ...workflowData } = handleWorkflow;

                delete workflowData.id;
                delete workflowData.hasCampanhas;
                delete workflowData.created_at;
                delete workflowData.updated_at;

                const response = await Post(`${process.env.REACT_APP_API_URL}/workflows`, workflowData);
                const newWorkflowId = response.id;

                const updatedWorkflow = {
                    ...workflowData,
                    id: newWorkflowId,
                    etapas: etapas.map((etapa) => {
                        const { id, ...rest } = etapa;
                        return {
                            ...rest,
                            workflow: newWorkflowId,
                        };
                    }),
                };

                await Patch(`${process.env.REACT_APP_API_URL}/workflows/${newWorkflowId}`, updatedWorkflow);

                showMessage({ variant: 'success', message: 'Workflow clonado com sucesso' }, setMessage);
                navigate(`/workflows/editar/${newWorkflowId}`);
            } else {
                if (handleWorkflow.id) {
                    await Patch(`${process.env.REACT_APP_API_URL}/workflows/${handleWorkflow.id}`, handleWorkflow);
                    showMessage({ variant: 'success', message: 'Workflow alterado com sucesso' }, setMessage);
                } else {
                    const response = await Post(`${process.env.REACT_APP_API_URL}/workflows`, handleWorkflow);
                    showMessage({ variant: 'success', message: 'Workflow inserido com sucesso' }, setMessage);
                    navigate(`/workflows/editar/${response.id}`);
                }
            }
            setUpdated((prev) => !prev);
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteWorkflowEtapa = async (id) => {
        try {
            await apiDeleteWorkflowEtapa(id);
            setUpdated((prev) => !prev);
            showMessage({ variant: 'success', message: 'Etapa excluída com sucesso' }, setMessage);
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddEtapa = () => {
        const newEtapa = { id: null, etapa: '', etapaTitulo: '', aprovacaoInterna: false }; // Exemplo de estrutura de uma nova etapa
        setEtapasView((prev) => [...prev, newEtapa]);
    };

    const handleUpdateEtapa = (etapaData, index) => {
        setEtapasView((prev) => prev.map((etapa, idx) => (idx === index ? etapaData : etapa)));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id) {
                    const data = await Get(`${process.env.REACT_APP_API_URL}/workflows/${id}`);
                    if (data) setWorkflow(data);
                }
            } catch (error) {
                showError(error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [id, updated]);

    useEffect(() => {
        const updateFormAndEtapas = async () => {
            if (workflow.id) {
                setForm({
                    nome: workflow.nome || '',
                    planilha: workflow.planilha || '',
                    planilhaCampanhaItem: workflow.planilhaCampanhaItem || '',
                });
                try {
                    const data = await apiGetEtapas();
                    if (data) setEtapas(data);
                } catch (error) {
                    showError(error);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        updateFormAndEtapas();
    }, [workflow]);

    useEffect(() => {
        const checkCampanhas = async () => {
            if (workflow.id) {
                try {
                    const campanhas = await apiGetCampanhas({ workflow: workflow.id });
                    if (campanhas && campanhas.length > 0) {
                        setWorkflow((prev) => ({ ...prev, hasCampanhas: true }));
                    }
                } catch (error) {
                    showError(error);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        checkCampanhas();
    }, [workflow.id]);

    useEffect(() => {
        if (workflow?.etapas?.length > 0) {
            setEtapasView(workflow.etapas);
        }
    }, [workflow.etapas, updated]);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box
                    sx={{
                        margin: 2,
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'var(--white-color)',
                    }}
                >
                    <Avatar
                        sx={{
                            m: 1,
                            mt: 3,
                            bgcolor: 'var(--primary-color)',
                        }}
                    >
                        <AccountTree />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {!workflow.id ? 'Adicionar Workflow' : 'Alterar Workflow'}
                    </Typography>
                    <Box component="form" sx={{ boxSizing: 'border-box', width: '100%', p: 4 }} onSubmit={handleSubmit}>
                        {message.message && <Alert variant={message.variant} message={message.message} />}
                        <InputLabel>Nome do Workflow</InputLabel>
                        <TextField
                            required
                            fullWidth
                            name="nome"
                            id="nome"
                            value={form.nome}
                            onChange={handleInputChange}
                            autoComplete="Nome do Workflow"
                        />
                        <input type="hidden" name="planilha" id="planilha" value={form.planilha} />
                        <input
                            type="hidden"
                            name="planilhaCampanhaItem"
                            id="planilhaCampanhaItem"
                            value={form.planilhaCampanhaItem}
                        />
                        {!workflow.id ? (
                            <ButtonFL type="submit" fullWidth textKey="Continuar" />
                        ) : (
                            <>
                                {etapasView.map((etapa, index) => (
                                    <WorkflowEtapa
                                        key={index}
                                        hasCampanhas={workflow.hasCampanhas}
                                        WorkflowEtapa={etapa}
                                        etapas={etapas}
                                        handleUpdateEtapa={(etapaData) => handleUpdateEtapa(etapaData, index)}
                                        handleDeleteWorkflowEtapa={handleDeleteWorkflowEtapa}
                                    />
                                ))}

                                {!workflow.hasCampanhas ? (
                                    <Box sx={{ pt: 3 }}>
                                        <ButtonFL onClick={handleAddEtapa} fullWidth textKey="Inserir Nova Etapa" />
                                        <ButtonFL type="submit" fullWidth textKey="Salvar" />
                                    </Box>
                                ) : (
                                    <ButtonFL
                                        onClick={handleSubmit}
                                        type="submit"
                                        fullWidth
                                        textKey="Salvar uma Cópia"
                                    />
                                )}
                            </>
                        )}
                    </Box>
                </Box>
            )}
        </>
    );
};
