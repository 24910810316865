import { Get } from '../data/Verbs';

export const apiGetCampanhaItem = async (id) => {
    try {
        const response = await Get(`${process.env.REACT_APP_API_URL}/campanhasItens/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
};
