import { Get } from '../data/Verbs';

const objectToQueryString = (params) => {
    return Object.keys(params)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
};

export const apiGetCampanhasItens = async (campanha, filtros = {}) => {
    try {
        let url = `${process.env.REACT_APP_API_URL}/campanhasItens?campanha=${campanha}&sort=id_asc`;

        if (Object.keys(filtros).length > 0) {
            const queryString = objectToQueryString(filtros);
            url += `&${queryString}`;
        }

        const response = await Get(url);
        return response;
    } catch (error) {
        throw error;
    }
};
