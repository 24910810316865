import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { handleOpenCreatorFromNotification, storageCreator } from '../../utils/handleStorage.utils';

export const CampaignCardsColumn = ({ groupedItems, creatorIdentify, toggleChat, children }) => {
    const [itemWidth, setItemWidth] = useState('');

    useEffect(() => {
        const calculateWidth = () => {
            if (!groupedItems || Object.keys(groupedItems)?.length === 0) {
                return;
            }

            const calculatedItemWidth = Math.floor((100 / 3).toFixed(2));

            setItemWidth(calculatedItemWidth);
        };

        calculateWidth();

        window.addEventListener('resize', calculateWidth);

        if (
            handleOpenCreatorFromNotification() == 1 &&
            groupedItems[creatorIdentify].creator == Number(storageCreator)
        ) {
            toggleChat(groupedItems[creatorIdentify]);
        }

        return () => window.removeEventListener('resize', calculateWidth);
    }, [groupedItems?.length]);

    return (
        <Box
            sx={{
                bgcolor: 'var(--white-color)',
                borderRadius: 2,
                width: { xs: '100%', md: `${itemWidth - 1}% ` },
                maxWidth: '320px',
                flexShrink: 0,
                marginRight: 2,
                marginBottom: 15,
            }}
        >
            {children}
        </Box>
    );
};
