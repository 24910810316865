import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Get } from '../../data/Verbs';
import { Loader } from '../Loader';
import { useError } from '../../contexts/ErrorContext';

export const Categorias = ({ item, setItem }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [escopos, setEscopos] = useState([]);

    const { showError } = useError();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Get(`${process.env.REACT_APP_API_URL}/escopos?sort=categoria_asc,subcategoria_asc`);
                setEscopos(data);
            } catch (error) {
                showError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [item]);

    return (
        <>
            {isLoading && <Loader />}

            <Autocomplete
                fullWidth
                id="categorias-autocomplete"
                variant="filled"
                options={escopos}
                getOptionLabel={(option) => `${option.categoria} - ${option.subcategoria}`}
                value={
                    escopos.find(
                        (option) => option.categoria === item.categoria && option.subcategoria === item.subcategoria,
                    ) || null
                }
                onChange={(event, newValue) => {
                    setItem({
                        ...item,
                        categoria: newValue ? newValue.categoria : '',
                        subcategoria: newValue ? newValue.subcategoria : '',
                    });
                }}
                renderInput={(params) => (
                    <TextField {...params} label="Entrega" variant="filled" size="small" fullWidth />
                )}
            />
        </>
    );
};
