import { Business } from '@mui/icons-material';
import { Avatar, Box, FormControl, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { ButtonFL } from '../../components/ButtonFL';

export const Cliente = ({ cliente, handleFechar, handleSalvar }) => {
    const [form, setForm] = useState({
        nome: cliente.nome || '',
        descricao: cliente.descricao || '',
    });

    const handleSubmit = (event) => {
        event.preventDefault();

        const updated = {
            ...cliente,
            nome: form.nome,
            descricao: form.descricao,
        };

        handleSalvar(updated);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setForm({
            ...form,
            [name]: value,
        });
    };

    return (
        <Box
            sx={{
                margin: 2,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'var(--white-color)',
            }}
        >
            <Avatar
                sx={{
                    m: 1,
                    mt: 3,
                    bgcolor: 'var(--primary-color)',
                }}
            >
                <Business />
            </Avatar>

            <Typography component="h1" variant="h5">
                {cliente.id ? 'Adicionar Cliente' : 'Alterar Cliente'}
            </Typography>

            <Box component="form" onSubmit={handleSubmit} sx={{ boxSizing: 'border-box', width: '100%', p: 4 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="nome"
                    label="Nome do Cliente"
                    id="nome"
                    value={form.nome}
                    onChange={handleInputChange}
                    autoComplete="Nome do Cliente"
                />
                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="descricao">Descrição</InputLabel>
                    <OutlinedInput
                        multiline
                        minRows={3}
                        name="descricao"
                        id="descricao"
                        value={form.descricao}
                        onChange={handleInputChange}
                        label="Descrição"
                    />
                </FormControl>

                <ButtonFL
                    type="submit"
                    fullWidth
                    textKey="Salvar"
                    sx={{ mt: 3, mb: 2, backgroundColor: 'var(--primary-color)' }}
                />

                <ButtonFL fullWidth textKey="Voltar" onClick={() => handleFechar(false)} />
            </Box>
        </Box>
    );
};
