import { Cancel, Edit } from '@mui/icons-material';
import { IconButton, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Box } from '@mui/material';
import React from 'react';

export const EscopoData = ({ escopo, handleOnEditEscopoItem, handleDeleteEscopoItem }) => {
    if (!escopo.data || escopo.data.length === 0) {
        return null;
    }

    return (
        <TableContainer sx={{ marginTop: 2 }}>
            <Table size="small">
                <TableBody>
                    {escopo.data.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <Grid container spacing={1}>
                                    <Grid item xs={2}>
                                        {item.quantidade}
                                    </Grid>
                                    <Grid item xs={7}>
                                        {item.categoria} - {item.subcategoria}
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box sx={{ marginLeft: 2 }}>{item.titulo}</Box>
                                    </Grid>
                                    <Grid item xs={1} style={{ textAlign: 'right' }}>
                                        <IconButton onClick={() => handleOnEditEscopoItem(item)}>
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteEscopoItem(item)}>
                                            <Cancel />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
