export const Get = async (url) => {
    try {
        const usuario = getUsuarioIdFromLocalStorage();
        const response = await fetch(url, {
            headers: {
                Authorization: process.env.REACT_APP_TOKEN,
                usuario,
            },
        });

        const responseData = await response.json();

        if (response.status !== 200 && response.status !== 204) {
            throw responseData;
        }

        return responseData;
    } catch (error) {
        throw error;
    }
};

export const Download = async (url) => {
    const usuario = getUsuarioIdFromLocalStorage();
    const response = await fetch(url, {
        headers: {
            Authorization: process.env.REACT_APP_TOKEN,
            usuario,
        },
    });

    if (response.status !== 200) {
        throw new Error('Erro ao processar fetch');
    }

    return response.text();
};

export const Excluir = async (url) => {
    try {
        const usuario = getUsuarioIdFromLocalStorage();
        const response = await fetch(url, {
            headers: {
                Authorization: process.env.REACT_APP_TOKEN,
                usuario,
            },
            method: 'DELETE',
        });

        const responseData = await response.json();

        if (response.status !== 200) {
            throw responseData;
        }

        return responseData;
    } catch (error) {
        throw error;
    }
};

export const Post = async (url, data) => {
    try {
        const usuario = getUsuarioIdFromLocalStorage();
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN,
                usuario,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        const responseData = await response.json();

        if (response.status !== 200) {
            throw responseData;
        }

        return responseData;
    } catch (error) {
        throw error;
    }
};

export const Patch = async (url, data) => {
    const usuario = getUsuarioIdFromLocalStorage();
    const isFormData = data instanceof FormData;
    const headers = { Authorization: process.env.REACT_APP_TOKEN, usuario };

    if (!isFormData) {
        headers['Content-Type'] = 'application/json';
        data = JSON.stringify(data);
    }

    try {
        const response = await fetch(url, {
            method: 'PATCH',
            headers: headers,
            body: data,
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw errorData;
        }

        return response.json();
    } catch (error) {
        throw error;
    }
};

export const getUserKey = () => {
    const isDevEnvironment = window.location.hostname.includes('dev');
    return isDevEnvironment ? 'dev_admin_user' : 'prod_admin_user';
};

const getUsuarioIdFromLocalStorage = () => {
    const storedUsuario = localStorage.getItem(getUserKey());
    if (storedUsuario) {
        try {
            const usuario = JSON.parse(storedUsuario);
            return usuario.id ? usuario.id : null;
        } catch (e) {
            console.error('Erro ao analisar o usuário do localStorage', e);
            return null;
        }
    } else {
        return null;
    }
};
