import React from 'react';
import { Cancel, TaskAlt } from '@mui/icons-material';
import { Box } from '@mui/material';
import { ButtonFL } from '../../ButtonFL';
import { IcampanhasItensStatus } from '../../../data/Interfaces';

export const CampaignActionButtonsCard = ({ selectedItem, handleOnAproveCampanhaItem, handleOpenItemReproveModal }) => {
    const labelApproved = selectedItem.status === IcampanhasItensStatus.WAITING ? 'Aprovar' : 'Aprovação do Cliente';
    const labelReproved = selectedItem.status === IcampanhasItensStatus.WAITING ? 'Reprovar' : 'Reprovação do Cliente';
    return (
        <Box gap={2} sx={{ display: ' flex', width: '100%' }}>
            <Box width={'50%'}>
                <ButtonFL
                    startIcon={<TaskAlt />}
                    bgcolor="var(--success-color)"
                    bgColorHover="var(--success-light-color)"
                    textKey={labelApproved}
                    onClick={() => handleOnAproveCampanhaItem(selectedItem?.id)}
                    fullWidth
                />
            </Box>
            <Box width={'50%'}>
                <ButtonFL
                    startIcon={<Cancel />}
                    bgcolor="var(--error-color)"
                    bgColorHover="var(--error-light-color)"
                    textKey={labelReproved}
                    onClick={() => handleOpenItemReproveModal(selectedItem)}
                    fullWidth
                />
            </Box>
        </Box>
    );
};
