import { Search, Clear } from '@mui/icons-material';
import { Chip, Grid, Autocomplete, TextField, IconButton, InputAdornment } from '@mui/material';
import React from 'react';
import { IcampanhasItensStatus } from '../../data/Interfaces';

export const Filters = ({ campanhaItens, creators, filtroCreator, setFiltroCreator, pendingFilterActive }) => {
    const pendingCampanhaItens = campanhaItens.filter(
        (item) => item.status === IcampanhasItensStatus.WAITING || item.status === IcampanhasItensStatus.WAITING_CLIENT,
    );

    const campanhaItensToConsider = pendingFilterActive ? pendingCampanhaItens : campanhaItens;
    const filteredCreators = creators.filter((creator) =>
        campanhaItensToConsider.some((item) => item.creator == creator.id),
    );

    const emptyCreatorsCount = campanhaItensToConsider.filter((item) => !item.creator).length;

    const autocompleteOptions = [{ nome: `Todos (${campanhaItensToConsider.length})`, id: null }];

    if (emptyCreatorsCount > 0) {
        autocompleteOptions.push({ nome: `Vazio (${emptyCreatorsCount})`, id: 'empty' });
    }

    const filteredCreatorsList = autocompleteOptions.concat(
        filteredCreators.map((creator) => ({
            ...creator,
            nome: `${creator.nome} (${campanhaItensToConsider.filter((item) => item.creator == creator.id).length})`,
        })),
    );

    const chipStyles = {
        marginLeft: 1,
        padding: 1,
        marginY: 1,
        backgroundColor: 'var(--primary-color)',
        color: 'var(--white-color)',
        '&:hover': {
            backgroundColor: 'var(--primary-dark-color)',
        },
        '&:active': {
            color: 'var(--grey-300-color)',
        },
    };

    const selectedChipStyles = {
        backgroundColor: 'var(--primary-color)',
        color: 'var(--white-color)',
    };

    const handleFilter = async (value) => {
        setFiltroCreator(value);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {filteredCreators.length > 6 ? (
                    <Autocomplete
                        options={filteredCreatorsList}
                        getOptionLabel={(option) => option.nome}
                        onChange={(event, newValue) => {
                            handleFilter(newValue?.id || null);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                label="Filtrar por creator"
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {params.InputProps.endAdornment}
                                            {filtroCreator && (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => {
                                                            handleFilter(null);
                                                            params.inputProps.onChange({ target: { value: '' } });
                                                        }}
                                                    >
                                                        <Clear />
                                                    </IconButton>
                                                </InputAdornment>
                                            )}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        clearOnEscape
                        clearOnBlur
                        sx={{ width: { xs: '100%', md: 700 }, my: 4 }}
                    />
                ) : (
                    <Grid item xs={12} sm={6}>
                        <Chip
                            label={`Todos (${campanhaItensToConsider.length})`}
                            onClick={() => {
                                filtroCreator != null ? handleFilter(null) : {};
                            }}
                            icon={<Search />}
                            clickable
                            sx={{
                                ...chipStyles,
                                ...(filtroCreator === null ? selectedChipStyles : {}),
                            }}
                        />

                        {emptyCreatorsCount > 0 && (
                            <Chip
                                label={`Vazio (${emptyCreatorsCount})`}
                                onClick={() => (filtroCreator != 'empty' ? handleFilter('empty') : {})}
                                sx={{
                                    ...chipStyles,
                                    ...(filtroCreator === 'empty' ? selectedChipStyles : {}),
                                }}
                                clickable
                            />
                        )}

                        {filteredCreators.map((creator) => {
                            const title = creator.nome;
                            const id = creator.id;
                            const count = campanhaItensToConsider.filter((item) => item.creator == id).length;

                            return (
                                <Chip
                                    key={id}
                                    label={`${title} (${count})`}
                                    onClick={() => (filtroCreator != id ? handleFilter(id) : {})}
                                    sx={{
                                        ...chipStyles,
                                        ...(filtroCreator === id ? selectedChipStyles : {}),
                                    }}
                                    clickable
                                />
                            );
                        })}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
