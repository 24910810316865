import React, { useState, useEffect, useCallback } from 'react';
import { Avatar } from '@mui/material';
import { getFirstLetterString } from '../../utils';

export const AvatarFL = ({
    name,
    onClick = null,
    width = 40,
    height = 40,
    fontSize = 18,
    color = 'var(--primary-color)',
}) => {
    const [firstLetter, setFirstLetter] = useState('');

    const getFirstLetter = useCallback(() => {
        const firstLetter = getFirstLetterString(name);
        setFirstLetter(firstLetter);
    }, [name]);

    useEffect(() => {
        getFirstLetter();
    }, []);

    return (
        <>
            <Avatar
                sx={{
                    bgcolor: color,
                    width,
                    height,
                    fontSize,
                }}
                onClick={onClick}
            >
                {firstLetter}
            </Avatar>
        </>
    );
};
