import { useEffect, useState } from 'react';

import { AddCircle } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { ButtonFL } from '../../components/ButtonFL';

import styles from '../../_assets/css/generic.module.css';
import { mensagemVazio, showMessage } from '../../data/Interfaces';
import { Excluir, Get } from '../../data/Verbs';
import { Alert } from '../../components/common/Alert';
import { Loader } from '../../components/Loader';
import { Search } from '../../components/common/Search';
import { CampanhasList } from './CampanhasList';
import { CommonMessage } from '../../components/common/CommonMessage';
import { CampanhasToExcel } from './CampanhasToExcel';
import { useNotificationStore } from '../../store';
import { useError } from '../../contexts/ErrorContext';
import { alertColor } from '../../utils';

export function Campanhas() {
    const [query, setQuery] = useState(localStorage.getItem('campanhas-query') || '');
    const [ordenacao, setOrdenacao] = useState('id_desc');
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [message, setMessage] = useState(mensagemVazio);
    const [campanhas, setCampanhas] = useState([]);

    const { fetchNotifications } = useNotificationStore((state) => ({
        fetchNotifications: state.fetchNotifications,
    }));

    const { showError } = useError();

    const ExcluirCampanha = async (campanha) => {
        setIsLoading(true);

        try {
            await Excluir(`${process.env.REACT_APP_API_URL}/campanhas/${campanha.id}`);

            fetchNotifications();

            showMessage(
                {
                    variant: alertColor.success,
                    message: 'Campanha excluida com sucesso',
                },
                setMessage,
            );

            setReload(!reload);
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleBusca = (_query) => {
        setQuery(_query);
    };

    const handleOrdenar = (campo) => {
        if (ordenacao === `${campo}_asc`) {
            setOrdenacao(`${campo}_desc`);
        } else {
            setOrdenacao(`${campo}_asc`);
        }
    };

    const fetchCampanhasData = async () => {
        try {
            const data = await Get(`${process.env.REACT_APP_API_URL}/campanhas?query=${query}&sort=${ordenacao}`);
            setCampanhas(data);
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCampanhasData();
    }, [query, ordenacao, reload]);

    useEffect(() => {
        localStorage.setItem('campanhas-query', query);
    }, [query]);

    return (
        <>
            {isLoading && <Loader />}
            <div className={styles.container}>
                {message.message && <Alert variant={message.variant} message={message.message} />}

                <Search
                    query={query}
                    handleAdicionar={
                        <ButtonFL
                            component={Link}
                            to="/campanhas/novo"
                            variant="contained"
                            startIcon={<AddCircle />}
                            sx={{ mt: 1, mb: 1 }}
                            textKey="Adicionar"
                        />
                    }
                    handleBusca={handleBusca}
                />

                <CommonMessage
                    message={
                        query == ''
                            ? 'Mostrando todas as Campanhas '
                            : campanhas.length == 0
                            ? 'Não foram encontradas campanhas cadastradas'
                            : `Listando ${campanhas.length} campanhas para a sua busca: ${query}`
                    }
                />

                <CampanhasList
                    handleExcluir={ExcluirCampanha}
                    handleOrdenar={handleOrdenar}
                    campanhas={campanhas}
                    ordenacao={ordenacao}
                />

                <CampanhasToExcel />
            </div>
        </>
    );
}
