import React, { useRef } from 'react';
import { LibraryAdd } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { ButtonFL } from '../ButtonFL';

const ButtonImport = ({ handleImportCreator }) => {
    const fileInputRef = useRef(null);

    const handleClickImport = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const worksheet = workbook.Sheets['Add Creators'];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                const keys = jsonData[1];
                const dataRows = jsonData.slice(2);

                const formattedData = dataRows
                    .map((row) => {
                        if (!row[0]) {
                            return null;
                        }

                        let obj = {
                            nome: row[0],
                            email: row[1],
                            telefone: row[2],
                            descricao: row[3],
                            instagram: row[4],
                            tiktok: row[5],
                            briefing: row[6],
                            layouts: [],
                        };

                        for (let i = 7; i < row.length; i += 4) {
                            if (row[i] || row[i + 1] || row[i + 2] || row[i + 3]) {
                                let layout = {
                                    Tipo: row[i] || '',
                                    ID: row[i + 1] || '',
                                    Qtd: row[i + 2] || '',
                                    Titulo: row[i + 3] || '',
                                };
                                obj.layouts.push(layout);
                            }
                        }

                        return obj;
                    })
                    .filter((row) => row !== null);

                handleImportCreator(formattedData);
            };

            reader.readAsArrayBuffer(file);
        }
    };

    return (
        <>
            <ButtonFL
                startIcon={<LibraryAdd color="white" />}
                label="Creator"
                onClick={handleClickImport}
                textKey="Importar Creators"
            />
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept=".xlsx, .xls, .csv"
                onChange={handleFileChange}
            />
        </>
    );
};

export default ButtonImport;
