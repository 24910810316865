// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#533289', // Defina a nova cor primária
        },
        blue: {
            main: '#3a005a', // Defina a nova cor primária
        },
        // Outras propriedades do tema, se necessário
    },
});

export default theme;
