import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { ButtonFL } from '../ButtonFL';

export const ErrorModal = ({ open, handleClose, code, labelButton }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="error-dialog-title"
            aria-describedby="error-dialog-description"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="error-dialog-title" sx={{ color: 'var(--primary-dark-color)', position: 'relative' }}>
                Ops! Algo deu errado
                {code && (
                    <span
                        style={{
                            position: 'absolute',
                            top: 8,
                            right: 16,
                            fontSize: '0.8rem',
                            color: 'rgba(0, 0, 0, 0.5)',
                        }}
                    >
                        {code}
                    </span>
                )}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="error-dialog-description">
                    {
                        'Nossos sistemas estão passando por instabilidades. Por Favor, tente novamente em alguns instantes. '
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ButtonFL textKey={labelButton || 'Ok, Entendi'} onClick={handleClose} />
            </DialogActions>
        </Dialog>
    );
};
