import React, { useState, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tab, Tabs, Modal } from '@mui/material';

import { CampaignActionButtonsCard } from '../../../components/CampainItemModal/CampainActionButtonsCard';

import { TextViewer } from '../../TextViewer';
import { TextEditor } from '../../TextEditor';
import { MediaViewer } from '../../MediaViewer';
import VideoInfoCard from '../../VideoInfoCard';
import { IcampanhasItensStatus } from '../../../data/Interfaces';
import { identifyFileType } from '../../../utils';
import { Alert } from '../../../components/common/Alert';

export const CampainWorkflowStepDetails = ({
    open,
    onClose,
    item,
    etapa,
    handleOnAproveCampanhaItem,
    handleOpenItemReproveModal,
    message,
    videoInfo,
}) => {
    const briefing = decodeURIComponent(item?.escopoBriefing || item?.campanhaBriefing || '');
    const typeFile = identifyFileType(etapa?.dados[0]?.valor);

    const isImage = typeFile === 'image';
    const isVideo = typeFile === 'video';
    const isText = etapa?.dados[0]?.tipo === 'texto';
    const isDownloadFile = (typeFile === 'unknown' || typeFile === 'document') && etapa?.dados[0]?.tipo !== 'texto';

    const [tabValue, setTabValue] = useState(isVideo ? 0 : 1);
    const [frameSrc, setFrameSrc] = useState('');

    const handleTabChange = (event, newValue) => setTabValue(newValue);
    const handleFrameClick = (src) => setFrameSrc(src);

    const renderCampaignActionButtons = useMemo(() => {
        const shouldDisplayButtons =
            item?.status === IcampanhasItensStatus.WAITING_CLIENT || item?.status === IcampanhasItensStatus.WAITING;

        if (shouldDisplayButtons && etapa?.concluido !== 1) {
            return (
                <Box width={'50vh'}>
                    <CampaignActionButtonsCard
                        selectedItem={item}
                        handleOnAproveCampanhaItem={handleOnAproveCampanhaItem}
                        handleOpenItemReproveModal={handleOpenItemReproveModal}
                    />
                </Box>
            );
        }
    }, [item, etapa, handleOnAproveCampanhaItem, handleOpenItemReproveModal]);

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                style={{
                    backgroundColor: 'rgba(0,0,0,0.7)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: 'none',
                }}
            >
                <>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        {message?.message !== '' && <Alert variant={message?.variant} message={message?.message} />}
                    </Box>
                    <IconButton
                        onClick={onClose}
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 20,
                            padding: '10px',
                            fontSize: '30px',
                            color: 'var(--white-color)',
                            cursor: 'pointer',
                            zIndex: 10000000,
                        }}
                        aria-label="close-video-player"
                    >
                        <CloseIcon fontSize="large" />
                    </IconButton>
                    <Box
                        sx={{
                            bgcolor: 'var(--grey-50-color)',
                            p: 4,
                            width: '80%',
                            height: '70vh',
                        }}
                        borderRadius={4}
                        display="flex"
                        flexDirection={{ xs: 'column', md: 'row' }}
                        gap={4}
                    >
                        <Box
                            flex={1}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            bgcolor={!isVideo ? 'var(--grey-50-color)' : 'rgba(0, 0, 0, 0.8)'}
                            borderRadius={4}
                        >
                            <MediaViewer
                                isVideo={isVideo}
                                isImage={isImage}
                                isDownloadFile={isDownloadFile}
                                isText={isText}
                                typeFile={typeFile}
                                etapa={etapa}
                            />
                        </Box>
                        <Box flex={1} display="flex" flexDirection="column">
                            <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth">
                                {videoInfo && <Tab label="Informações" />}
                                <Tab label="Briefing" />
                            </Tabs>
                            <Box flex={1}>
                                {tabValue === 0 && (
                                    <Box>
                                        <VideoInfoCard videoInfo={videoInfo} handleFrameClick={handleFrameClick} />
                                    </Box>
                                )}
                                {tabValue === 1 && (
                                    <Box>
                                        <TextEditor editorContent={briefing} isReadOnly height="50vh" />
                                    </Box>
                                )}
                            </Box>
                            <DialogActions>{renderCampaignActionButtons}</DialogActions>
                        </Box>
                    </Box>
                    {frameSrc && (
                        <Dialog open={Boolean(frameSrc)} onClose={() => setFrameSrc('')}>
                            <DialogTitle>Amostra</DialogTitle>
                            <DialogContent>
                                <Box textAlign="center">
                                    <img src={frameSrc} alt="Amostra" style={{ maxWidth: '100%', maxHeight: '70vh' }} />
                                </Box>
                            </DialogContent>
                        </Dialog>
                    )}
                </>
            </Modal>
        </>
    );
};
