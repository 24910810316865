import { Get } from '../data/Verbs';

export const apiGetEtapas = async () => {
    try {
        const response = await Get(`${process.env.REACT_APP_API_URL}/workflows/etapas/`);
        return response;
    } catch (error) {
        throw error;
    }
};
