import { Get } from '../data/Verbs';

export const apiGetGames = async () => {
    try {
        const response = await Get(`${process.env.REACT_APP_API_URL}/creators/jogos`);
        return response;
    } catch (error) {
        throw error;
    }
};
export const apiGetGenderGames = async () => {
    try {
        const response = await Get(`${process.env.REACT_APP_API_URL}/creators/jogos/generos`);
        return response;
    } catch (error) {
        throw error;
    }
};
export const apiGetCategories = async () => {
    try {
        const response = await Get(`${process.env.REACT_APP_API_URL}/creators/categorias`);
        return response;
    } catch (error) {
        throw error;
    }
};
