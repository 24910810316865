import { useEffect, useState } from 'react';
import { Box, Typography, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { Get } from '../../data/Verbs';
import { mensagemVazio, showMessage } from '../../data/Interfaces';
import { Alert } from '../../components/common/Alert';
import { ButtonFL } from '../../components/ButtonFL';
import { Loader } from '../../components/Loader';
import { TextEditor } from '../../components/TextEditor';
import { useError } from '../../contexts/ErrorContext';

export const CampanhaDados = ({ _handleSubmit, campanha, clientes }) => {
    const { showError } = useError();

    const [form, setForm] = useState({
        cliente: null,
        projeto: null,
        titulo: '',
        valor: '',
        aprova: false,
        template: '',
    });

    const decodedBriefing = decodeURIComponent(campanha?.briefing ?? '');

    const [cliente, setCliente] = useState('');
    const [projetos, setProjetos] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [message, setMessage] = useState(mensagemVazio);
    const [isLoading, setIsLoading] = useState(true);
    const [editorContent, setEditorContent] = useState(decodedBriefing);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const encodedBriefing = encodeURIComponent(editorContent);

        setIsLoading(true);

        const invalidFields = handleValidation();

        if (invalidFields.length > 0) {
            const messageText = `Os seguintes campos são obrigatórios: ${invalidFields.join(', ')}`;
            showMessage({ variant: 'warning', message: messageText }, setMessage);
            return;
        }

        const handleCampanha = {
            ...campanha,
            cliente: form.cliente,
            projeto: form.projeto,
            titulo: form.titulo,
            briefing: encodedBriefing,
            valor: form.valor,
            aprova: form.aprova,
            template: form.template,
        };

        _handleSubmit(handleCampanha);
        setIsLoading(false);
    };

    const handleValidation = () => {
        const requiredFields = ['cliente', 'projeto', 'titulo', 'template'];
        const invalidFields = [];

        requiredFields.forEach((fieldName) => {
            if (!form[fieldName]) {
                invalidFields.push(fieldName.charAt(0).toUpperCase() + fieldName.slice(1));
            }
        });

        return invalidFields;
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setForm({
            ...form,
            [name]: value,
        });

        if (name === 'cliente') {
            setCliente(value);
        }
    };

    const handleValorChange = (values) => {
        const { value } = values;
        setForm({ ...form, valor: value });
    };

    const handleSwitchChange = (event) => {
        setForm({
            ...form,
            aprova: event.target.checked,
        });
    };

    const fetchProjetos = async () => {
        try {
            const data = await Get(`${process.env.REACT_APP_API_URL}/projetos?cliente=${cliente}&sort=titulo_asc`);
            setProjetos(data);
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchTemplates = async () => {
        try {
            const data = await Get(`${process.env.REACT_APP_API_URL}/templates?cliente=${cliente}&sort=titulo_asc`);
            setTemplates(data);
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (campanha) {
            setForm({
                cliente: campanha.cliente || '',
                projeto: campanha.projeto || null,
                titulo: campanha.titulo || '',
                valor: campanha.valor,
                aprova: campanha.aprova || false,
                template: campanha.template || '',
            });

            campanha.cliente && setCliente(campanha.cliente);
        }
    }, [campanha]);

    useEffect(() => {
        fetchTemplates();
        if (cliente) {
            fetchProjetos();
        }
    }, [cliente]);

    const loading = isLoading || clientes.length === 0;

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    {message.message && <Alert variant={message.variant} message={message.message} />}

                    <form onSubmit={handleSubmit}>
                        <Box sx={{ padding: 2, backgroundColor: 'var(--white-color)' }}>
                            <InputLabel id="label-cliente" htmlFor="cliente">
                                Cliente
                            </InputLabel>

                            {clientes.length > 0 ? (
                                <Select
                                    labelId="label-cliente"
                                    name="cliente"
                                    id="cliente"
                                    label="Cliente"
                                    value={form.cliente}
                                    onChange={handleInputChange}
                                    fullWidth
                                >
                                    {clientes.map((cliente) => (
                                        <MenuItem key={cliente.id} value={cliente.id}>
                                            {cliente.nome}
                                        </MenuItem>
                                    ))}
                                </Select>
                            ) : (
                                <>
                                    <InputLabel>Cliente</InputLabel>
                                    <TextField
                                        fullWidth
                                        label="Cliente"
                                        autoComplete="Carregando Clientes..."
                                        disabled
                                    />
                                </>
                            )}

                            {form.cliente !== null &&
                                (projetos.length > 0 ? (
                                    <>
                                        <InputLabel htmlFor="projeto">Projeto</InputLabel>
                                        <Select
                                            name="projeto"
                                            id="projeto"
                                            label="Projeto"
                                            value={form.projeto ?? ''}
                                            onChange={handleInputChange}
                                            fullWidth
                                        >
                                            {projetos.map((_projeto) => (
                                                <MenuItem key={_projeto.id} value={_projeto.id}>
                                                    {_projeto.titulo}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </>
                                ) : (
                                    <>
                                        <InputLabel>Projeto</InputLabel>
                                        <TextField
                                            fullWidth
                                            label={
                                                !form.cliente
                                                    ? 'Selecione primeiro o Cliente'
                                                    : 'O Cliente selecionado não tem projetos cadastrados'
                                            }
                                            autoComplete="Selecione primeiro o Cliente"
                                            disabled
                                        />
                                    </>
                                ))}

                            <InputLabel>Título</InputLabel>
                            <TextField
                                name="titulo"
                                id="titulo"
                                value={form.titulo}
                                autoComplete="Título da Campanha"
                                onChange={handleInputChange}
                                fullWidth
                            />

                            <Box>
                                <Typography variant="body1" sx={{ py: 2, color: 'var(--grey-900-color)' }}>
                                    Briefing
                                </Typography>
                                <TextEditor setEditorContent={setEditorContent} editorContent={editorContent} />
                            </Box>

                            <InputLabel>Budget da Campanha</InputLabel>
                            <NumericFormat
                                value={form.valor}
                                customInput={TextField}
                                displayType={'input'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                decimalScale={2}
                                fixedDecimalScale
                                prefix={'R$ '}
                                onValueChange={handleValorChange}
                                fullWidth
                            />

                            <InputLabel>Aprovar Creators</InputLabel>
                            <FormControlLabel
                                control={<Switch checked={form.aprova} onChange={handleSwitchChange} name="aprova" />}
                                label="Aprovar Creators"
                            />

                            {!campanha.id ? (
                                <>
                                    <InputLabel htmlFor="template">Template</InputLabel>
                                    <Select
                                        name="template"
                                        id="template"
                                        label="template"
                                        value={form.template ?? ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                    >
                                        {templates.map((_template) => (
                                            <MenuItem key={_template.id} value={_template.id}>
                                                {_template.titulo}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </>
                            ) : (
                                <>
                                    <InputLabel>Template</InputLabel>
                                    <TextField
                                        fullWidth
                                        label={templates.find((t) => t.id === form.template)?.titulo}
                                        disabled
                                    />
                                </>
                            )}

                            {!campanha.id ? (
                                <ButtonFL type="submit" fullWidth textKey="Continuar" />
                            ) : (
                                <>
                                    <ButtonFL type="submit" fullWidth textKey="Salvar" />
                                </>
                            )}
                        </Box>
                    </form>
                </>
            )}
        </>
    );
};
