import {
    TextField,
    Typography,
    Box,
    FormControl,
    InputLabel,
    OutlinedInput,
    Switch,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { GenericModal } from '../../../components/common/GenericModal';
import { Creators } from '../../../components/common/Creators';
import { valorToDatabase } from '../../../utils';
import { ButtonFL } from '../../../components/ButtonFL';

export const EscopoCreatorModal = ({
    open,
    onClose,
    creators,
    creatorIdentify,
    creator,
    valor,
    campanhaBriefing,
    creatorsFiltered,
    handleSaveEscopoCreator,
}) => {
    const [editarBriefing, setEditarBriefing] = useState(false);
    const [briefingModal, setBriefing] = useState(campanhaBriefing);
    const [creatorModal, setCreator] = useState(creator || null);
    const [valorModal, setValor] = useState(valor || '');
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const handleValorChange = (valor) => {
        setValor(valor);
    };

    const handleSaveEscopoCreatorInterno = () => {
        const formattedValue = valorToDatabase(valorModal);

        if (editarBriefing) {
            setConfirmDialogOpen(true);
        } else {
            handleSaveEscopoCreator({
                creator: creatorModal,
                valor: formattedValue,
                escopoBriefing: briefingModal,
                creatorIdentify: creatorIdentify ? creatorIdentify : null,
            });

            setCreator(null);
            setValor('');
            setConfirmDialogOpen(false);
        }
    };

    const handleConfirmDialogClose = (confirmed) => {
        if (confirmed) {
            handleSaveEscopoCreator({
                creator: creatorModal,
                valor: valorModal,
                escopoBriefing: briefingModal,
                creatorIdentify: creatorIdentify ? creatorIdentify : null,
            });

            setCreator(null);
            setValor('');
        }

        setConfirmDialogOpen(false);
    };

    return (
        <GenericModal onClose={onClose} open={open}>
            <Box sx={{ padding: 3 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                    Selecione o Creator
                </Typography>
                <Typography variant="subtitle1">Selecionar Creator</Typography>
                <Creators
                    creators={creators}
                    creator={creatorModal}
                    creatorsFiltered={creatorsFiltered}
                    setCreator={setCreator}
                />
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                    Informe o valor do cachê
                </Typography>
                <NumericFormat
                    fullWidth
                    value={valorModal}
                    customInput={TextField}
                    displayType={'input'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={2}
                    fixedDecimalScale
                    prefix={'R$ '}
                    onValueChange={(values) => {
                        const { formattedValue } = values;

                        handleValorChange(formattedValue);
                    }}
                />

                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                    Editar o Briefing Geral
                </Typography>
                <FormControl fullWidth variant="outlined">
                    <Switch checked={editarBriefing} onChange={(e) => setEditarBriefing(e.target.checked)} />
                </FormControl>

                {editarBriefing && (
                    <FormControl fullWidth variant="outlined">
                        <TextField
                            multiline
                            minRows={3}
                            name="briefing"
                            id="briefing"
                            value={briefingModal}
                            onChange={(e) => {
                                setBriefing(e.target.value);
                            }}
                            label="Editar Briefing para todas as Entregas"
                        />
                    </FormControl>
                )}

                <ButtonFL onClick={handleSaveEscopoCreatorInterno} keyPrefix="atividades" textKey="Salvar" fullWidth />
            </Box>

            <Dialog open={confirmDialogOpen} onClose={() => handleConfirmDialogClose(false)}>
                <DialogTitle>Confirmação</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Ao confirmar o briefing do creator será alterado em todas as suas entregas dessa campanha. Se
                        preferir alterar o briefing de uma entrega em específica, clique no respectivo card e edite por
                        lá.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonFL
                        onClick={() => handleConfirmDialogClose(true)}
                        keyPrefix="confirmar"
                        textKey="Confirmar"
                    />
                    <ButtonFL onClick={() => handleConfirmDialogClose(false)} keyPrefix="cancelar" textKey="Cancelar" />
                </DialogActions>
            </Dialog>
        </GenericModal>
    );
};
