import { Post } from '../data/Verbs';

export const apiPostCampanhaItem = async (campanhaItem) => {
    try {
        const response = await Post(`${process.env.REACT_APP_API_URL}/campanhasItens`, {
            campanhaItem,
        });

        return response;
    } catch (error) {
        throw error;
    }
};
