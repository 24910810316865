import React from 'react';
import { Badge, IconButton, Fab } from '@mui/material';

const CustomBadge = ({ badgeContent, Icon, buttonProps, fabProps, isFab }) => {
    const validBadgeContent = typeof badgeContent === 'number' || typeof badgeContent == 'string' ? badgeContent : 0;

    if (isFab) {
        return (
            <Fab {...fabProps}>
                <Badge badgeContent={validBadgeContent} color="secondary">
                    <Icon />
                </Badge>
            </Fab>
        );
    }

    return (
        <IconButton {...buttonProps}>
            <Badge badgeContent={validBadgeContent} color="secondary">
                <Icon />
            </Badge>
        </IconButton>
    );
};

export default CustomBadge;
