import React from 'react';
import { Box, Typography, IconButton, TextField } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Check from '@mui/icons-material/Check';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const EditableDate = ({ isEditableDate, completedDateCard, handleDateClick, handleDateChange, onSaveDate }) => {
    const formattedDate = completedDateCard
        ? format(completedDateCard, 'dd/MM/yyyy', { locale: ptBR })
        : 'Editar Data Conclusão';

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
                justifyContent: 'end',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',

                    bgcolor: 'var(--grey-50-color)',
                    p: 1,
                    borderRadius: 3,
                }}
            >
                {completedDateCard && <Typography variant="caption">Data de entrega:</Typography>}
                {!isEditableDate ? (
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography variant="body2">{formattedDate}</Typography>
                        <IconButton onClick={handleDateClick} aria-label="edit-date">
                            <EditOutlinedIcon sx={{ fontSize: 18 }} />
                        </IconButton>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                            <DatePicker
                                value={completedDateCard}
                                onChange={handleDateChange}
                                mask="____-__-__"
                                inputFormat="dd/MM/yyyy"
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <IconButton
                            onClick={onSaveDate}
                            aria-label="save-date"
                            sx={{ backgroundColor: 'var(--primary-color)', marginLeft: 1 }}
                        >
                            <Check fontSize="small" sx={{ color: 'var(--white-color)' }} />
                        </IconButton>
                    </Box>
                )}
            </Box>
        </Box>
    );
};
