import AlertComponent from '@mui/material/Alert';

export const Alert = ({ variant, message }) => {
    return (
        <>
            {message && (
                <AlertComponent
                    variant="filled"
                    severity={variant}
                    sx={{
                        marginTop: 2,
                        marginBottom: 2,
                    }}
                >
                    {message}
                </AlertComponent>
            )}
        </>
    );
};
