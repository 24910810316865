import React from 'react';
import { Box, Typography, IconButton, TextField } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Check from '@mui/icons-material/Check';

export const EditableTitleCard = ({
    titleCard,
    isEditable,
    setIsEditable,
    handleTextClick,
    handleTextChange,
    onSaveTitle,
}) => {
    return (
        <>
            {!isEditable ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        my: 2,
                    }}
                >
                    <Box
                        sx={{
                            overflow: 'hidden',
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                wordBreak: 'break-word',
                                overflowWrap: 'break-word',
                                letterSpacing: 0.5,
                            }}
                        >
                            {titleCard}
                        </Typography>
                    </Box>
                    <IconButton onClick={() => setIsEditable(true)} aria-label="editModal">
                        <EditOutlinedIcon sx={{ fontSize: 18 }} />
                    </IconButton>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <TextField
                        value={titleCard}
                        onClick={handleTextClick}
                        onChange={handleTextChange}
                        fullWidth
                        InputProps={{
                            readOnly: !isEditable,
                        }}
                        sx={{
                            backgroundColor: 'var(--white-color)',
                            marginTop: 1,
                            borderRadius: 1,
                        }}
                    />
                    {isEditable && (
                        <IconButton
                            onClick={onSaveTitle}
                            aria-label="save"
                            sx={{ backgroundColor: 'var(--primary-color)', marginLeft: 1 }}
                        >
                            <Check fontSize="small" sx={{ color: 'var(--white-color)' }} />
                        </IconButton>
                    )}
                </Box>
            )}
        </>
    );
};
