import { Grid } from '@mui/material';

export function ClientesMessage({ message }) {
    return (
        <>
            <Grid
                component="div"
                sx={{
                    backgroundColor: 'white',
                    p: 2,
                    textAlign: 'center',
                    width: '100%',
                    my: 2,
                    borderRadius: '8px',
                }}
            >
                {message}
            </Grid>
        </>
    );
}
